.visibilityToggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    color: var(--interactive-color);

    & svg {
        width: 24px;
        height: 24px;
        fill: currentColor;
        margin-right: 6px;
    }
}

:global(.mcds-redesign-molecules) {
    .showHideToggle svg {
        width: calc(var(--base-unit) * 4);
        height: calc(var(--base-unit) * 4);
    }
}
