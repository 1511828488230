.root {
    background-color: var(--bg-lvl-1-color);

    &:first-of-type .wrapper {
        border-top: none;
    }
}

.wrapper {
    position: relative;
    padding: var(--spacing-5) 0;
    border-top: 1px solid var(--border-light-color);
}


/* Selectable Slats */
.interactive {
    padding: 0 var(--spacing-9);

    &:hover,
    &:focus-within {
        background-color: var(--bg-lvl-2-color);
    }
    @media screen and (--mq-max-width-phone) {
        padding: 0 var(--spacing-6);
    }
}

.selection {
    position: absolute;
    left: calc(calc(-1 * var(--spacing-8)));
    top: 50%; /*  To get absolute center positioning at all times depending on content */
    margin-top: calc(calc(-1 * var(--spacing-3))); /*  Half of checkbox height */

    @media screen and (--mq-max-width-phone) {
        position: relative;
        left: inherit;

        input {
            margin-bottom: var(--spacing-3);
        }
    }
}
