.root {
    position: relative;
}

.label {
    color: var(--color-text-inverse);
    border-radius: var(--radius-small);
    font-size: var(--font-size-component-x-small);
    min-width: var(--space-column-gap-medium);
    padding: var(--space-component-inline-padding-xx-small) var(--space-component-inline-padding-x-small);
    line-height: normal;
}

.default {
    background-color: var(--color-ui-neutral);
}

.error {
    background-color: var(--color-ui-negative);
}

.pinned {
    position: absolute;
    left: var(--space-medium);
    top: calc(-1 * var(--space-x-small));
}
