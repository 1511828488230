/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import (reference) './tokens.less';

// Typography guidelines can be found at https://designsystem.mailchimp.com/web/typography
:global {
    /*********************************************
        HEADINGS
    *********************************************/

    h1,
    .h1 {
        font: var(--font-heading-1);
        font-weight: var(--font-heading-weight);
        color: var(--text-headline-primary-color);
    }

    h2,
    .h2 {
        font: var(--font-heading-2);
        font-weight: var(--font-heading-weight);
        color: var(--text-headline-primary-color);
    }

    h3,
    .h3 {
        font: var(--font-heading-3);
        font-weight: var(--font-heading-weight);
        color: var(--text-headline-primary-color);
    }

    h4,
    .h4 {
        font: var(--font-heading-4);
        font-weight: var(--font-heading-weight);
        color: var(--text-headline-primary-color);
    }

    h5,
    .h5 {
        font: var(--font-heading-5);
        font-weight: var(--font-heading-weight);
        color: var(--text-headline-primary-color);
    }

    h6,
    .h6 {
        font: var(--font-heading-6);
        font-weight: var(--font-heading-weight);
        color: var(--text-headline-primary-color);
    }

    /*********************************************
        LINKS
        For accessibility purposes, inline links should also be underlined
    *********************************************/

    a {
        color: var(--interactive-color);
        text-decoration: underline;
        font-weight: var(--font-weight-bold);

        &:hover {
            color: var(--interactive-hover-color);
        }
    }

    // Typography
    code {
        color: var(--text-error-color);
        font-family: var(--typeface-monospace);

        .tag {
            color: var(--color-code-string);
        }
        .attr {
            color: var(--color-code-type);
        }
        .num {
            color: var(--color-code-number);
        }
    }

    strong {
        font-weight: var(--font-weight-bold);
    }

}
