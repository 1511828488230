@import (reference) '@mc/wink/styles/mixins.less';

.cluster > * {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
}

.nowrap > * {
    flex-wrap: nowrap;
}

// Adjustable main axis
.justifyFlexStart {
    justify-content: flex-start;
}
.justifyFlexEnd {
    justify-content: flex-end;
}
.justifyCenter {
    justify-content: center;
}
.justifySpaceBetween {
    justify-content: space-between;
}
.justifySpaceAround {
    justify-content: space-around;
}

// Adjustable item secondary axis
.alignItemsFlexStart {
    align-items: flex-start;
}
.alignItemsFlexEnd {
    align-items: flex-end;
}
.alignItemsCenter {
    align-items: center;
}
.alignItemsBaseline {
    align-items: baseline;
}
.alignItemsStretch {
    align-items: stretch;
}

.layout-spacing({
    .spacing@{size} {
        margin-top: @value * -1;
        margin-left: @value * -1;

        > * {
            margin-top: @value;
            margin-left: @value;
        }
    }
});
