.breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font: var(--font-text-small);

    svg {
        width: calc(var(--base-unit) * 2);
        height: calc(var(--base-unit) * 2);
        margin: 0 var(--base-unit);
        vertical-align: bottom;
    }

    .stepName {
        &:not([disabled]):not(.current):not(.notInteractive) {
            &:hover {
                background-color: var(--bg-lvl-3-color);
                box-shadow: 0 0 0 2px var(--bg-lvl-3-color);
            }

            &:focus {
                outline: 2px solid transparent;
                box-shadow: 0 0 0 2px var(--border-interactive-color);
            }
        }

        &[disabled]:not(.current) {
            color: var(--disabled-color);
        }

        &.notInteractive {
            color: var(--text-secondary-color);
        }

        &.current {
            font-weight: var(--font-weight-bold);
            border-bottom: 2px solid var(--border-interactive-color);
        }
    }

    .step {
        display: flex;
        align-items: center;
    }

    .disabledIcon {
        fill: var(--disabled-color);
    }
}

.steps {
    font: var(--font-text-small);
}
