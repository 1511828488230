:root {
    --popover-background: var(--bg-lvl-1-color);
}

.popover {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: var(--z-modal);
}

.shadow {
    background: var(--popover-background);
    box-shadow: var(--elevation-lvl-1);
}
