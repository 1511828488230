.wrapper {
    position: relative;
}

.tabList {
    display: flex;
    flex-direction: row;
    flex-flow: row;
    overflow-x: auto;
    box-shadow: inset 0 -1px 0 var(--border-color);


    /*  Browsers that support reduced motion will smooth scroll */

    /*  if the user has no preference for reduced motion */
    @media (prefers-reduced-motion: no-preference) {
        scroll-behavior: smooth;
    }
}


/*  Add .focus-within only when keyboard is detected for selection */
.focus-within:focus-within {
    outline: 2px solid var(--border-interactive-color);
}

.overflowNav {
    position: absolute;
    top: var(--spacing-2);
}

.overflowLeft {
    left: 0;

    /*  Gradient of overflow nav */

    /*  TO-DO Design is following up on creating a gradient token. To be replaced. */
    background: linear-gradient(90deg, var(--bg-lvl-1-color) 66.3%, transparent 78.42%);
    padding-right: var(--spacing-7);
}

.overflowRight {
    right: 0;

    /*  Gradient of overflow nav */

    /*  TO-DO Design is following up on creating a gradient token. To be replaced. */
    background: linear-gradient(270deg, var(--bg-lvl-1-color) 66.3%, transparent 78.42%);
    padding-left: var(--spacing-7);
}

.tabIndicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100px;
    background-color: var(--border-interactive-color);

    transition:
        width 250ms cubic-bezier(0.3, 0, 0.1, 1),
        left 150ms cubic-bezier(0.3, 0, 0.1, 1);

    @media (prefers-reduced-motion) {
        transition: none;
    }
}
