.root {
    max-width: var(--readable-max-width);
    vertical-align: middle;

    fieldset > & {
        font: var(--font-text-small);
    }

    .selectWrapper {
        position: relative;
    }

    .trigger,
    select {
        font: inherit;
        font-size: var(--input-font-size, var(--font-size-medium));
        line-height: var(--line-height-medium);
        width: 100%;
        display: block;
        padding-top: var(--input-padding-top, 10px);
        padding-bottom: var(--input-padding-bottom, 10px);
        padding-left: var(--input-padding-left, var(--spacing-4));
        padding-right: var(--input-padding-right, var(--spacing-4));
        border: 1px solid var(--border-input-color);
        color: var(--text-primary-color);
        background-color: var(--bg-lvl-1-color);
        border-radius: var(--border-input-radius);
        box-sizing: border-box;
        transition: box-shadow 200ms ease-in-out, border-color 200ms ease-in-out, background-color 150ms var(--animation-curve);

        /*  Mobile Safari workaround */
        background-clip: padding-box;

        &:not([disabled]):focus {
            outline: 2px solid var(--border-interactive-color);
            outline-offset: 1px;
            border: 1px solid var(--border-input-color-hover);

            /* Mobile Safari workaround */
            box-shadow: inset 0 -2px 0 transparent;
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: transparent;
            color: var(--disabled-color);
            border-color: var(--disabled-border-color);
        }

        &[readonly] {
            background-color: transparent;
        }

        &::placeholder {
            color: var(--text-secondary-color);
        }

        &::-ms-clear {
            display: none;
        }

        min-width: var(--select-min-width);
        max-width: var(--select-max-width);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        cursor: pointer;

        /*  Use extra padding-right to clear space for the dropdown indicator svg */
        padding-right: var(--spacing-7);


        /*  For button triggers */
        text-align: left;


        /*  Mobile Safari workaround */
        appearance: none;

        &:not([disabled]):not([readonly]):hover {
            background-color: var(--bg-interactive-color);
            border: 1px solid var(--border-input-color-hover);
            transition: background-color 50ms var(--animation-curve);
        }

        &[disabled] + .indicator svg {
            fill: var(--disabled-color);
        }


        /*  Firefox changes the <option> background based on prefers-color-scheme setting. If `dark` */

        /*  is selected our default select text style becomes unreadable. Configuring a background */

        /*  color for <option> progressively enhances the styles without blocking us from our own */

        /*  dark mode efforts. */
        option {
            background-color: var(--bg-lvl-1-color);
        }
    }

    .inputTrigger {
        cursor: text;
    }
}

.indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    padding: 0 var(--spacing-2);

    svg {
        fill: var(--icon-fill, var(--text-primary-color));
    }
}

.before {
    display: flex;
    justify-content: space-between;


    /*  Add bottom margin to `.before`'s direct children. If elements before the */

    /*  input are visually empty, no margin should display before the input. */
    > * {
        margin-bottom: var(--spacing-2);
    }
}


/*  Add top margin to elements after the input. If no elements exist after the */

/*  input, no margin should display after the input. */
.after {
    margin-top: var(--spacing-2);
}

.secondary {
    color: var(--text-secondary-color);
}

.error select,
.error .trigger {
    border-color: var(--border-error-color);

    &:focus {
        outline-color: var(--border-error-color);
    }
}

.errorMessage {
    color: var(--text-error-color);

    a {
        color: inherit;
    }
}

:global(.mcds-redesign-molecules) {
    .root {
        display: flex;
        flex-direction: column;
        gap: var(--space-component-gap-small);

        .trigger,
        select {
            background: var(--color-input-background-primary);
            border-radius: var(--radius-small);
            border: 1px solid var(--color-input-border-primary);
            color: var(--color-text-primary);
            font-family: var(--font-family-component);
            transition: box-shadow 200ms ease-in-out, border-color 200ms ease-in-out, background-color 150ms var(--animation-curve);
            padding-right: var(--space-container-padding-x-large);
            line-height: var(--line-height-component);

            &:not([disabled]):focus {
                background: var(--color-input-background-primary-focus);
                border-color: var(--color-input-border-primary-focus);
                box-shadow: none;
                outline-offset: -2px;
                outline: 2px solid var(--color-input-border-primary-focus);
            }

            &[disabled] {
                background: var(--color-input-background-disabled);
                border-color: var(--color-input-border-disabled);
                color: var(--color-text-disabled);
                opacity: 1;
            }

            &[readonly] {
                background: var(--color-input-background-readonly);
                border-color: var(--color-input-border-readonly);
            }

            &::placeholder {
                color: var(--color-text-quaternary);
            }

            &:not([disabled]):not([readonly]):hover {
                background: var(--color-input-background-primary-hover);
                border-color: var(--color-input-border-primary-hover);
            }

            &[disabled] + .indicator svg {
                fill: var(--color-icon-disabled);
            }

            /*  Firefox changes the <option> background based on prefers-color-scheme setting. If `dark` */

            /*  is selected our default select text style becomes unreadable. Configuring a background */

            /*  color for <option> progressively enhances the styles without blocking us from our own */

            /*  dark mode efforts. */
            option {
                background-color: var(--color-page-background-primary);
            }
        }
    }

    .indicator {
        padding: 0 var(--space-container-padding-xx-small);

        svg {
            fill: var(--color-icon-primary);
        }
    }

    .before {
        display: flex;
        align-items: center;
        gap: var(--space-component-gap-x-small);

        .required {
            color: var(--color-ui-negative);
            font-size: var(--font-size-component-x-small);
            font-weight: 500;
            line-height: var(--line-height-component);
        }

        :global(.mcds-label-default) {
            flex-grow: 1;
            color: var(--color-text-secondary);
            font-weight: var(--font-weight-body);
        }

        > * {
            margin-bottom: 0;
        }
    }

    .after {
        margin-top: 0;
        display: flex;
        align-items: center;
        gap: var(--space-component-gap-small);

        .message {
            color: var(--color-text-secondary);
            font-size: var(--font-size-component-x-small);
            line-height: var(--line-height-component);

            a {
                color: inherit;
            }
        }

        svg {
            color: var(--color-text-inverse);
            height: var(--space-medium);
            width: var(--space-medium);
        }
    }

    .secondary {
        color: var(--color-text-secondary);
        font-size: var(--font-size-component-x-small);
        line-height: var(--line-height-component);
    }

    .miscText {
        color: var(--color-text-tertiary);
        font-size: var(--font-size-component-x-small);
        line-height: var(--line-height-component);
    }

    .error {
        select,
        .trigger {
            border-color: var(--color-input-border-error);
            background-color: var(--color-input-background-error);

            &:not([disabled]):not([readonly]):hover {
                background-color: var(--color-input-background-error);
                border-color: var(--color-input-border-error);
            }

            &:not([disabled]):focus,
            &:not([disabled]):focus:hover {
                background: var(--color-input-background-error-focus);
                outline-color: var(--color-input-border-error);
            }
        }

        .after {
            svg path {
                fill: var(--color-ui-negative);
            }
            .message {
                color: var(--color-ui-negative);
            }
        }
    }

    .warning {
        select,
        .trigger {
            border-color: var(--color-input-border-warning);
            background-color: var(--color-input-background-warning);

            &:not([disabled]):not([readonly]):hover {
                background-color: var(--color-input-background-warning);
                border-color: var(--color-input-border-warning);
            }

            &:not([disabled]):focus,
            &:not([disabled]):focus:hover {
                background: var(--color-input-background-warning-focus);
                outline-color: var(--color-input-border-warning);
            }
        }

        .after {
            svg circle {
                fill: var(--color-ui-attention);
            }
        }
    }

    .large {
        .before {
            :global(.mcds-label-default) {
                font-size: var(--font-size-component-small);
                line-height: var(--line-height-component);
            }
        }

        .trigger,
        select {
            font-size: var(--font-size-component-small);
            padding-top: var(--space-component-stack-padding-x-large);
            padding-bottom: var(--space-component-stack-padding-x-large);
            padding-left: var(--space-component-inline-padding-large);
            height: calc(var(--base-unit) * 10);
        }

        .miscText {
            font-size: var(--font-size-component-small);
        }
    }

    .medium {
        .before {
            :global(.mcds-label-default) {
                font-size: var(--font-size-component-x-small);
                line-height: var(--line-height-component);
            }
        }

        .trigger,
        select {
            font-size: var(--font-size-component-x-small);
            padding-top: var(--space-component-stack-padding-large);
            padding-bottom: var(--space-component-stack-padding-large);
            padding-left: var(--space-component-inline-padding-medium);
            height: calc(var(--base-unit) * 8);
        }
    }

    .small {
        .before {
            :global(.mcds-label-default) {
                font-size: var(--font-size-component-x-small);
                line-height: var(--line-height-component);
            }
        }

        .trigger,
        select {
            font-size: var(--font-size-component-x-small);
            padding-top: var(--space-component-stack-padding-small);
            padding-bottom: var(--space-component-stack-padding-small);
            padding-left: var(--space-component-inline-padding-medium);
            height: calc(var(--base-unit) * 6);
        }
    }

    .disabled {
        .before {
            :global(.mcds-label-default) {
                color: var(--color-text-disabled);
            }
        }

        .secondary {
            color: var(--color-text-disabled);
        }

        .after .message {
            color: var(--color-text-disabled);
        }
    }
}
