/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

/*********************************************
UTILITY CLASSES
Utility classes were created for common elements to be reused within the app.
If a class is needed for specific features, consider scoping or adding a new variant to the respective component.
*********************************************/

:global {
    // !important is important. We really, truly want the elements to be hidden
    // and not conflict with any :global{} overrides.
    .wink-visually-hidden:not(:focus):not(:active) {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        margin: -1px !important;
        padding: 0 !important;
        overflow: hidden !important;
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        clip-path: inset(50%) !important;
        white-space: nowrap !important;
    }

    // LISTS
    // Created for default usage of ordered and unordered lists. Using standard spacing as noted at MDN
    // https://developer.mozilla.org/en-US/docs/Learn/CSS/Styling_text/Styling_lists
    .mcds-list-ul-default {
        margin: var(--spacing-4) 0;
        padding: 0 0 0 2.5em;
        list-style-type: disc;

        li {
            margin-bottom: var(--spacing-4);
        }
    }
    .mcds-list-ol-default {
        margin: var(--spacing-4) 0;
        padding: 0 0 0 2.5em;
        list-style-type: decimal;

        li {
            margin-bottom: var(--spacing-4);
        }
    }

    // LABELS
    // Created for default label styles. Used by <label>s and elements that
    // act as a label (via `aria-labelledby` or some other).
    .mcds-label-default {
        font-weight: var(--font-weight-bold);
    }

    .mcds-boxed-input {
        font: inherit;
        font-size: var(--input-font-size, var(--font-size-medium));
        line-height: var(--line-height-medium);
        width: 100%;
        display: block;
        padding-top: var(--input-padding-top, 10px);
        padding-bottom: var(--input-padding-bottom, 10px);
        padding-left: var(--input-padding-left, var(--spacing-4));
        padding-right: var(--input-padding-right, var(--spacing-4));
        border: 1px solid var(--border-input-color);
        color: var(--text-primary-color);
        background-color: var(--bg-lvl-1-color);
        border-radius: var(--border-input-radius);
        box-sizing: border-box;
        transition:
            box-shadow 200ms ease-in-out,
            border-color 200ms ease-in-out,
            background-color 150ms var(--animation-curve);
        // Mobile Safari workaround
        background-clip: padding-box;

        &:not([disabled]):focus {
            outline: 2px solid var(--border-interactive-color);
            outline-offset: 1px;
            border: 1px solid var(--border-input-color-hover);

            // Mobile Safari workaround
            box-shadow: inset 0 -2px 0 transparent;
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: transparent;
            color: var(--disabled-color);
            border-color: var(--disabled-border-color);
        }

        &[readonly] {
            background-color: transparent;
        }

        &::placeholder {
            color: var(--text-secondary-color);
        }

        &::-ms-clear {
            display: none;
        }
    }
}
