.root {
    /*
    * Default value is separate but our reset.less sets it to collapse.
    * In order to round borders, we have to let cells have their own border.
    *
    * Ref: https://css-tricks.com/almanac/properties/b/border-collapse/
    */

    border-collapse: separate;
    border: 1px solid var(--border-light-color);
    border-radius: var(--border-data-radius) var(--border-data-radius) 0 0;
    font: var(--font-text-small);

    caption {
        margin-bottom: var(--spacing-4);
    }

    thead {
        background-color: var(--bg-lvl-3-color);

        th {
            font-weight: var(--font-weight-bold);
            text-align: left;
            padding: var(--spacing-4);
            vertical-align: middle;
        }

        /*  Round the top corners of table */
        th:first-child {
            border-radius: var(--border-data-radius) 0 0 0;
        }

        th:last-child {
            border-radius: 0 var(--border-data-radius) 0 0;
        }
    }

    tbody tr {
        background-color: var(--bg-lvl-1-color);
    }

    thead + tbody tr,
    tr:not(:first-child) {
        box-shadow: inset 0 1px 0 var(--border-light-color);
    }

    td,
    th[scope='row'] {
        padding: var(--spacing-5) var(--spacing-4);
        vertical-align: middle;
        text-align: left;
    }
}

.checkbox input {
    margin-left: var(--spacing-4);
    display: flex;
    align-items: center;
}

.striped {
    tbody tr:nth-child(even) {
        background-color: var(--bg-lvl-2-color);
    }
}

.fixedFirstColumn {
    border-left: 0;
    position: relative;
    z-index: 0;

    /* Makes the first cell in each row fixed. */
    tr > *:first-child {
        position: sticky;
        left: 0;
        z-index: var(--z-base);
        background-color: var(--bg-lvl-1-color);
        box-shadow:
            inset 1px 0 0 var(--border-light-color),
            inset -1px 1px 0 var(--border-light-color);

        /* Adds a drop shadow to the right of the first column. */
        &::after {
            box-shadow:
                inset var(--spacing-3) 0 var(--spacing-3)
                calc(var(--spacing-3) * -1) var(--border-light-color);
            content: ' ';
            height: 100%;
            position: absolute;
            top: 0;
            right: calc(var(--spacing-3) * -1);
            width: var(--spacing-3);
            pointer-events: none;
        }
    }

    /* Add some space to the left of the second column for the fixed column drop shadow. */
    tr > *:nth-child(2) {
        padding-left: var(--spacing-8);
    }

    /* Styles specific to the first column header. */
    tr > th:not([scope='row']):first-child {
        background-color: var(--bg-lvl-3-color);
        box-shadow:
            inset 1px 0 0 var(--border-light-color),
            inset -1px 0 0 var(--border-light-color);
    }
}

.stickyHeader {
    position: relative;
    z-index: 0;

    /* Makes the table header fixed. */
    thead {
        position: sticky;
        top: 0;
        z-index: calc(var(--z-base) + 1);

        /**Setting z-index to 2 is for making sure the first column cell of the first row will sticky
         when users choose fixed first column and sticky header together*/
    }
}
