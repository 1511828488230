.withBottomGutter {
    padding-bottom: var(--spacing-9);
    @media screen and (--mq-max-width-phone) {
        padding-bottom: var(--spacing-4);
    }
}

.withTopGutter {
    padding-top: var(--spacing-9);
    @media screen and (--mq-max-width-phone) {
        padding-top: var(--spacing-4);
    }
}

