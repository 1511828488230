/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */


:root {
    --checkmark-size: calc(var(--base-unit) * 6);
}

.choice {
    display: flex;

    background: var(--bg-lvl-1-color);
    border: 1px solid var(--border-light-color);
    border-radius: var(--border-card-radius);

    box-sizing: border-box;

    transition: var(--box-shadow-transition);
    box-shadow: none;

    > * + * {
        margin-left: var(--spacing-4);
    }

    &:hover {
        border-color: var(--border-input-color-hover);
        background: var(--bg-lvl-2-color);
        cursor: pointer;
    }

    *:hover {
        cursor: pointer;
    }

    &:focus-within {
        background: var(--bg-lvl-2-color);
        border-color: var(--border-interactive-color);
        outline: 1px solid var(--border-interactive-color);
    }

    &.checked {
        border-color: var(--border-interactive-color);
        background: var(--bg-interactive-color);

        &:focus-within {
            background: var(--bg-interactive-color);
            outline: 1px solid var(--border-interactive-color);
        }
    }

    &.disabled {
        border-color: var(--disabled-color);
        background: var(--bg-lvl-1-color);
        opacity: 0.3;
        cursor: not-allowed;
        * { cursor: not-allowed; }
    }

    input {
        float: right;
    }
}

.error .choice {
    border-color: var(--border-error-color);

    &:focus {
        outline-color: var(--border-error-color);
    }
}

.errorMessage {
    color: var(--text-error-color);

    a {
        color: inherit;
    }
}

.card {
    width: 100%;
    padding: var(--spacing-6);
}

.input[type="radio"] {
    /*  adding 1px to the width/height for buffer around input icon to avoid cut-off when zooming in browser */
    width: calc(var(--checkmark-size) + 1px);
    height: calc(var(--checkmark-size) + 1px);
    overflow: hidden;
    margin: 0;
    flex-shrink: 0;
    border: 1px solid var(--border-input-color);
    border-radius: 50%;
    appearance: none;
    outline: none;

    &:checked {
        border-color: var(--border-interactive-color);

        &::before {
            content: '';
            background-color: var(--interactive-color);
            border-radius: 50%;
            border: 5px solid var(--bg-lvl-1-color);
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &:focus {
        border-color: var(--border-interactive-color);
        border-width: 2px;
    }

    &:checked:focus {
        border-color: var(--interactive-hover-color);
        border-width: 3px;

        &::before {
            border-width: 4px;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: var(--disabled-color);
    }
}

.input[type="checkbox"] {
    appearance: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    margin: 0;
    width: calc(var(--base-unit) * 6);
    height: calc(var(--base-unit) * 6);
    border: 1px solid var(--border-input-color);
    transition: background-color 0.2s linear;


    /*  We can swap this with our svg tick mark in future iterations if preferred */
    &::after {
        content: '';
        display: block;
        border-left: 2px solid var(--bg-lvl-1-color);
        border-bottom: 2px solid var(--bg-lvl-1-color);
        margin: 6px 5px;
        height: 6px;
        width: calc(var(--base-unit) * 3);
        opacity: 0;
        transform: rotate(-45deg);
        transition: opacity 0.2s linear;
    }

    &:checked {
        border-color: var(--text-inverted-color);
        background-color: var(--interactive-color);
        &::after {
            opacity: 1;
        }
    }

    &:focus {
        outline: 2px solid var(--border-interactive-color);
        outline-offset: 1px;
    }

    &:checked:focus {
        border-color: var(--border-dark-color);
    }

    &[disabled],
    &[disabled]::after {
        background-color: var(--bg-lvl-3-color);
        border-color: var(--disabled-color);
        cursor: not-allowed;
    }
}

.image {
    height: calc(var(--base-unit) * 16);
    width: calc(var(--base-unit) * 16);
}

.label {
    max-width: var(--readable-max-width);
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-1);
}

.simpleLabel {
    max-width: var(--readable-max-width);
    margin-bottom: var(--spacing-1);
}

.description {
    color: var(--text-secondary-color);
    overflow-wrap: anywhere;
}

.simple {
    [type="radio"],
    [type="checkbox"] {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .card {
        padding: var(--spacing-3);
    }
}
