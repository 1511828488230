@import (reference) '@mc/wink/styles/mixins.less';

.stack {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.stack > * {
    margin-top: 0;
    margin-bottom: 0;
}

.layout-spacing({
    .spacing@{size} {
        > * + * {
            margin-top: @value;
        }

        > :global(.wink-visually-hidden):first-child + * {
            margin-top: 0;
        }
    }
});
