.root {
    display: flex;
    align-items: flex-start;
}

.text {
    max-width: var(--readable-max-width);
    .labelDisabled {
        color: var(--disabled-color);
    }
}

.description {
    color: var(--text-secondary-color);
}

/* Wrapper to add click area around checkbox input */
.checkboxContainer {
    width: calc(var(--base-unit) * 6);
    height: calc(var(--base-unit) * 6);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    svg {
        position: absolute;
        color: var(--color-icon-complementary);
    }
}

.checkboxContainerDisabled {
    cursor: not-allowed;
}

.input {
    appearance: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    margin: 0;
    width: calc(var(--base-unit) * 6);
    height: calc(var(--base-unit) * 6);
    border: 1px solid var(--border-input-color);
    border-radius: var(--border-input-radius);

    transition: background-color 0.2s linear;

    /*  Used for value="true" */

    /*  We can swap this with our svg tick mark in future iterations if preferred */
    &::after {
        content: '';
        display: block;
        visibility: hidden;

        /*  The border must match the base background. */
        border-left: 2px solid var(--bg-lvl-1-color);
        border-bottom: 2px solid var(--bg-lvl-1-color);
        margin: 6px 5px;
        height: 6px;
        width: calc(var(--base-unit) * 3);
        opacity: 0;
        transform: rotate(-45deg);
        transition: opacity 0.2s linear;
    }

    /*  Used for indeterminate="true" */

    /*  We can swap this with our svg minus in future iterations if preferred */
    &::before {
        content: '';
        display: none;

        /*  The border must match the base background. */
        border-bottom: 2px solid var(--bg-lvl-1-color);
        margin: 6px 5px;
        height: 6px;
        width: calc(var(--base-unit) * 3);
        opacity: 0;
        transition: opacity 0.2s linear;
    }

    &:checked {
        border-color: var(--border-interactive-color);
        background-color: var(--interactive-color);
        &::after {
            opacity: 1;
            visibility: visible;
        }
    }

    &[indeterminate=true] {
        border-color: var(--border-interactive-color);
        background-color: var(--interactive-color);
        &::before {
            display: block;
            opacity: 1;
        }
    }

    &:focus {
        outline: 2px solid var(--border-interactive-color);
        outline-offset: 1px;
    }

    &[disabled],
    &[disabled]::after {
        background-color: var(--bg-lvl-3-color);
        border-color: var(--disabled-border-color);
        cursor: not-allowed;
    }

    &:not(.noMargin) {
        margin-right: var(--spacing-4);
    }
}

/*  Error messaging */
.error .input {
    border-color: var(--border-error-color);

    &:focus {
        outline: 2px solid var(--border-error-color);
        outline-offset: 1px;
    }
}

.errorMessage {
    color: var(--text-error-color);

    a {
        color: inherit;
    }
}


:global(.mcds-redesign-molecules) {
    .root {
        gap: var(--space-component-gap-medium);
    }
    .label {
        cursor: pointer;
        padding: var(--space-component-stack-padding-x-small) 0;
        color: var(--color-text-primary);
        font-family: var(--font-family-component);
        font-size: var(--font-size-component-small);
        font-weight: var(--font-weight-component);
        line-height: var(--line-height-component);
    }
    .label.labelDisabled {
        cursor: not-allowed;
    }
    .errorMessage,
    .description {
        padding-top: var(--space-component-stack-padding-x-small);
        font-family: var(--font-family-body);
        font-size: var(--font-size-body-4);
        font-weight: var(--font-weight-body);
        line-height: var(--line-height-body);
    }

    .input {
        --mcds-input-background-hover: var(--color-input-background-tertiary-hover);
        --mcds-input-border-hover: var(--color-input-border-tertiary-hover);

        border: 1px solid var(--color-input-border-tertiary);
        width: calc(var(--base-unit) * 5);
        height: calc(var(--base-unit) * 5);
        background-color: var(--color-input-background-tertiary);
        &::after {
            margin: 5px 3px;
            height: 6px;
            border-color: var(--color-icon-inverse);
        }

        &::before {
            margin: 4px 3px;
            height: 6px;
            border-color: var(--color-icon-inverse);
        }

        &:hover {
            border-color: var(--mcds-input-border-hover);
            background-color: var(--mcds-input-background-hover);
        }

        &:active {
            &:not(:checked):not([indeterminate=true]):not(:disabled) {
                border: 2px solid var(--color-input-border-tertiary-active);
            }
        }

        &:focus {
            outline: none;
        }

        /* Add a custom focus outline only when the element is focused using the keyboard */
        &:focus-visible {
            outline: 2px solid var(--border-interactive-color);
        }

        &:checked,
        &[indeterminate=true] {
            border-color: var(--color-input-border-primary-selected);
            background-color: var(--color-input-background-primary-selected);
        }

        &[disabled],
        &[disabled]::after {
            background-color: var(--color-input-background-disabled);
            border-color: var(--color-icon-inverse);
        }

        &[disabled] {
            border-color: var(--color-input-border-disabled);
        }

        &:not(.noMargin) {
            margin-right: 0;
        }
    }

    .error .input {
        border-color: var(--color-input-border-error);
        background-color: var(--color-input-background-error);
        &:hover {
            --mcds-input-background-hover: var(--color-input-background-error);
            --mcds-input-border-hover: var(--color-input-border-error);
        }

        &:checked,
        &:checked:hover,
        &[indeterminate=true] {
            border-color: var(--color-container-border-negative);
            background-color: var(--color-action-negative);
        }

        &:active {
            &:not(:checked):not([indeterminate=true]) {
                border: 2px solid var(--color-container-border-negative);
            }
        }
        &:focus {
            outline: none;
        }

        /* Add a custom focus outline only when the element is focused using the keyboard */
        &:focus-visible {
            outline: 2px solid var(--border-error-color);
        }
    }
}
