@import (reference) '@mc/wink/styles/mixins.less';

// Note that an intermediary wrapper has been employed to insulate the
// negative margin, making the Flex parent `.switcher > *`.
// (-- From every-layout.dev)
.switcher > * {
    --measure: var(--readable-max-width);

    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.switcher > * > * {
    flex-grow: 1;
    // See below comments for an explanation.
    flex-basis: ~"calc((var(--measure) - 100%) * 999)";
    min-width: 0;

    // Sometimes flex-basis + flex-wrap causes items to poke out of the
    // container. max-width: 100% fixes possible overflow.
    max-width: 100%;
}

.layout-spacing({
    .spacing@{size} {
        margin-top: @value * -1;
        margin-left: @value * -1;

        > * {
            // You can read a more in-depth explanation of this in the Flexbox
            // Holy Albatross. `999` essentially forces `flex-basis` to be `0`
            // or `100%`. If `0`, it's a single-row layout. If `100%`, it's a
            // single-column layout.
            flex-basis: ~"calc((var(--measure) - (100% - @{value})) * 999)";
            margin-top: @value;
            margin-left: @value;
        }
    }
});
