.root {
    max-width: var(--readable-max-width);
}

/*  HEADING SETS */
.heading-2 {
    font: var(--font-heading-2);
    font-weight: var(--font-heading-weight);
    letter-spacing: var(--letter-spacing-heading);
}

.heading-3 {
    font: var(--font-heading-3);
    font-weight: var(--font-heading-weight);
    letter-spacing: var(--letter-spacing-heading);
}

.heading-4 {
    font: var(--font-heading-4);
    font-weight: var(--font-heading-weight);
    letter-spacing: var(--letter-spacing-heading);
}

.heading-5 {
    font: var(--font-heading-5);
    font-weight: var(--font-heading-weight);
}

.heading-6 {
    font: var(--font-heading-6);
    font-weight: var(--font-heading-weight);
}

/*  LARGE */
.large {
    font: var(--font-text-large);
    letter-spacing: var(--letter-spacing-body);
}

.large-secondary {
    font: var(--font-text-large);
    color: var(--text-secondary-color);
    letter-spacing: var(--letter-spacing-body);
}

.large-bold {
    font: var(--font-text-large);
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing-body-bold);
}

/*  MEDIUM */
.medium {
    font: var(--font-text-medium);
    letter-spacing: var(--letter-spacing-body);
}

.medium-secondary {
    font: var(--font-text-medium);
    color: var(--text-secondary-color);
    letter-spacing: var(--letter-spacing-body);
}

.medium-bold {
    font: var(--font-text-medium);
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing-body-bold);
}

/*  SMALL */
.small {
    font: var(--font-text-small);
    letter-spacing: var(--letter-spacing-body);
}

.small-secondary {
    font: var(--font-text-small);
    color: var(--text-secondary-color);
    letter-spacing: var(--letter-spacing-body);
}

.small-bold {
    font: var(--font-text-small);
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing-body-bold);
}

/*  SMALL */
.tiny {
    font: var(--font-text-tiny);
    letter-spacing: var(--letter-spacing-body);
}

.tiny-secondary {
    font: var(--font-text-tiny);
    color: var(--text-secondary-color);
    letter-spacing: var(--letter-spacing-body);
}

.tiny-tertiary {
    font: var(--font-text-tiny);
    color: var(--color-text-tertiary);
    font-size: var(--font-size-component-x-small);
    letter-spacing: var(--letter-spacing-body);
}

.tiny-bold {
    font: var(--font-text-tiny);
    font-weight: var(--font-weight-bold);
    letter-spacing: var(--letter-spacing-body-bold);
}

/*  TEXT WRAP */
.text-wrap {
    text-wrap: wrap;
}
.text-nowrap {
    text-wrap: nowrap;
}
.text-balance {
    text-wrap: balance;
}
.text-pretty {
    text-wrap: pretty;
}

/*  [EEE3-2693]: Fix for crashing email checklist. Wrap with <span>s to get around a Google Translate issue. */
.temporarySpan {
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    white-space: inherit;
}

:global(.mcds-redesign-molecules) {
    /*  HEADING SETS */
    .heading-2 {
        font-size: var(--font-size-heading-2);
        font-weight: var(--font-weight-heading);
        letter-spacing: var(--letter-spacing-heading);
        line-height: var(--line-height-h2);
    }

    .heading-3 {
        font-size: var(--font-size-heading-3);
        font-weight: var(--font-weight-heading);
        letter-spacing: var(--letter-spacing-heading);
        line-height: var(--line-height-h3);
    }

    .heading-4 {
        font-size: var(--font-size-heading-4);
        font-weight: var(--font-weight-heading);
        letter-spacing: var(--letter-spacing-heading);
        line-height: var(--line-height-h4);
    }

    .heading-5 {
        font-size: var(--font-size-heading-5);
        font-weight: var(--font-weight-heading);
        line-height: var(--line-height-h5);
    }

    .heading-6 {
        font-size: var(--font-size-heading-6);
        font-weight: var(--font-weight-heading);
        line-height: var(--line-height-h6);
    }

    /*  LARGE */
    .large {
        font-size: var(--font-size-component-large);
        letter-spacing: var(--letter-spacing-component);
        line-height: var(--line-height-large);
    }

    .large-secondary {
        font-size: var(--font-size-component-large);
        color: var(--color-text-secondary);
        letter-spacing: var(--letter-spacing-component);
        line-height: var(--line-height-large);
    }

    .large-tertiary {
        font-size: var(--font-size-component-large);
        color: var(--color-text-tertiary);
        letter-spacing: var(--letter-spacing-component);
        line-height: var(--line-height-large);
    }

    .large-bold {
        font-size: var(--font-size-component-large);
        font-weight: var(--font-weight-component-bold);
        letter-spacing: var(--letter-spacing-component-bold);
        line-height: var(--line-height-large);
    }

    /*  MEDIUM */
    .medium {
        font-size: var(--font-size-component-medium);
        letter-spacing: var(--letter-spacing-component);
        line-height: var(--line-height-medium);
    }

    .medium-secondary {
        font-size: var(--font-size-component-medium);
        color: var(--color-text-secondary);
        letter-spacing: var(--letter-spacing-component-body);
        line-height: var(--line-height-medium);
    }

    .medium-tertiary {
        font-size: var(--font-size-component-medium);
        color: var(--color-text-tertiary);
        letter-spacing: var(--letter-spacing-component);
        line-height: var(--line-height-medium);
    }

    .medium-bold {
        font-size: var(--font-size-component-medium);
        font-weight: var(--font-weight-component-bold);
        letter-spacing: var(--letter-spacing-component-bold);
        line-height: var(--line-height-medium);
    }

    /*  SMALL */
    .small {
        font-size: var(--font-size-component-small);
        letter-spacing: var(--letter-spacing-component);
        line-height: var(--line-height-small);
    }

    .small-secondary {
        font-size: var(--font-size-component-small);
        font-weight: var(--font-weight-component);
        color: var(--color-text-secondary);
        letter-spacing: var(--letter-spacing-component);
        line-height: var(--line-height-small);
    }

    .small-tertiary {
        font-size: var(--font-size-component-small);
        color: var(--color-text-tertiary);
        letter-spacing: var(--letter-spacing-component);
        line-height: var(--line-height-small);
    }

    .small-bold {
        font-size: var(--font-size-component-small);
        font-weight: var(--font-weight-component-bold);
        letter-spacing: var(--letter-spacing-component-bold);
        line-height: var(--line-height-small);
    }

    /* TINY */
    .tiny {
        font-size: var(--font-size-component-x-small);
        letter-spacing: var(--letter-spacing-component);
        line-height: var(--line-height-tiny);
    }

    .tiny-secondary {
        font-size: var(--font-size-component-x-small);
        color: var(--color-text-secondary);
        letter-spacing: var(--letter-spacing-component);
        line-height: var(--line-height-tiny);
    }

    .tiny-tertiary {
        font-size: var(--font-size-component-x-small);
        color: var(--color-text-tertiary);
        letter-spacing: var(--letter-spacing-component);
        line-height: var(--line-height-tiny);
    }

    .tiny-bold {
        font-size: var(--font-size-component-x-small);
        font-weight: var(--font-weight-component-bold);
        letter-spacing: var(--letter-spacing-component-bold);
        line-height: var(--line-height-tiny);
    }
}
