.root {
    display: flex;

    > * + * {
        margin-left: var(--spacing-4);
    }
}

.text {
    max-width: var(--readable-max-width);
}

.description {
    color: var(--text-secondary-color);
}

.labelDisabled {
    color: var(--disabled-color);
}

.input {
    --checkmark-size: calc(var(--base-unit) * 6);

    /*  adding 1px to the width/height for buffer around input icon to avoid cut-off when zooming in browser */
    width: calc(var(--checkmark-size) + 1px);
    height: calc(var(--checkmark-size) + 1px);
    overflow: hidden;
    margin: 0;
    flex-shrink: 0;
    border: 1px solid var(--border-input-color);
    border-radius: 50%;
    appearance: none;
    outline: none;

    &:checked {
        border-color: var(--border-interactive-color);

        &::before {
            content: '';
            background-color: var(--interactive-color);
            border-radius: 50%;
            border: 5px solid var(--bg-lvl-1-color);
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &:focus {
        border-color: var(--interactive-hover-color);
        border-width: 2px;
    }

    &:checked:focus {
        border-color: var(--interactive-hover-color);
        border-width: 3px;

        &::before {
            border-width: 4px;
        }
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: var(--bg-lvl-3-color);
        border-color: var(--disabled-border-color);
        &:checked {
            &::before {
                background-color: var(--disabled-border-color);
                border-color: var(--bg-lvl-3-color);
            }
        }
    }
}

/*  Error messaging */
.error .input {
    border-color: var(--border-error-color);

    &:focus {
        outline: 2px solid var(--border-error-color);
        outline-offset: 1px;
    }
}

.errorMessage {
    color: var(--text-error-color);

    a {
        color: inherit;
    }
}

:global(.mcds-redesign-molecules) {
    .root {
        display: flex;
        align-items: flex-start;

        > * + * {
            margin-left: var(--space-component-inline-padding-medium);
        }
    }

    .text {
        max-width: var(--readable-max-width);
    }

    .description {
        color: var(--color-text-secondary);
        font-size: var(--font-size-body-4);
        line-height: var(--line-height-body);
    }

    .label {
        font-size: var(--font-size-component-small);
        &[disabled] {
            color: var(--color-text-disabled);
        }
    }

    .input[type='radio'] {
        width: calc(var(--radius-x-small) * 5);
        height: calc(var(--radius-x-small) * 5);
        overflow: hidden;
        margin: var(--space-component-stack-padding-x-small) 0 0 0;
        flex-shrink: 0;
        border: 1px solid var(--color-input-border-tertiary);
        border-radius: 50%;
        background-color: var(--color-input-background-primary);

        &:active {
            border: 2px solid var(--color-input-border-tertiary-active);
        }

        &:hover,
        &:focus {
            border-color: var(--color-input-border-tertiary-hover);
            background-color: var(--color-input-background-primary-hover);
        }

        &:focus-visible {
            border: 1px solid var(--color-input-border-tertiary-focus);
            outline: 2px solid var(--color-input-border-tertiary-active);
            outline-offset: 2px;
        }

        &:checked {
            border: none;
            &::before {
                /* In this scenario, background-color is the inner circle and border-color is the radio's background fill.
                    This is due to how we create the selected circle */
                background-color: var(--color-icon-inverse);
                border-radius: var(--radius-full);
                border: 5px solid var(--color-input-border-primary-selected);
            }
        }

        &:checked:focus-visible {
            outline: 2px solid var(--color-input-border-primary-selected);
            &::before {
                /* In this scenario, background-color is the inner circle and border-color is the radio's background fill.
                    This is due to how we create the selected circle */
                background-color: var(--color-icon-inverse);
                border-radius: var(--radius-full);
                border: 5px solid var(--color-input-border-primary-selected);
            }
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: var(--color-input-background-disabled);
            border: 1px solid var(--color-input-border-disabled);
            &:checked {
                &::before {
                    /* In this scenario, background-color is the inner circle and border-color is the radio's background fill.
                       This is due to how we create the selected circle */
                    background-color: var(--color-icon-inverse);
                    border-radius: var(--radius-full);
                    border: 4px solid var(--color-input-background-disabled);
                }
            }
        }
    }

    /*  Error messaging */
    .error .input {
        border-color: var(--color-input-border-error);
        background-color: var(--color-input-background-error);

        &:focus {
            outline: 2px solid var(--color-input-border-error);
            outline-offset: 1px;
        }
    }

    .errorMessage {
        color: var(--color-ui-negative);

        a {
            color: inherit;
        }
    }
}
