.root {
    display: flex;
    gap: var(--space-component-inline-padding-medium);
    flex-wrap: nowrap;
    align-items: center;
    font-size: var(--font-size-component-small);
}

.dot {
    display: inline-block;
    width: var(--space-component-stack-padding-large);
    height: var(--space-component-stack-padding-large);
    border-radius: 50%;
}

.default .dot { background-color: var(--color-ui-neutral); }
.new .dot { background-color: var(--color-ui-new); }
.active .dot { background-color: var(--color-ui-discover); }
.success .dot { background-color: var(--color-ui-positive); }
.warning .dot { background-color: var(--color-ui-attention); }
.error .dot { background-color: var(--color-ui-negative); }
.info .dot { background-color: var(--color-ui-info); }
