/*  General Styles */
.root {
    background-color: var(--bg-lvl-1-color);
    box-shadow: var(--elevation-lvl-1);
    padding: var(--spacing-6);
    width: 330px;
    height: auto;
    display: flex;
    border: 1px solid var(--bg-lvl-3-color);
    box-sizing: border-box;
    position: relative;
    border-radius: var(--border-card-radius);
}

.closeIcon {
    position: absolute;
    top: var(--spacing-2);
    right: var(--spacing-2);
}

.image {
    width: 160px;
    height: auto;
    margin: 0 auto var(--spacing-2);
}
