/*------------------------------------*\
    #Action Card Root
\*------------------------------------*/

.root {
    display: grid;
    grid-template-columns: 152px 4fr;
    min-height: 152px;

    &.isVertical {
        grid-template-columns: 1fr;

        .imgWrapper {
            min-height: 200px;
            border-radius: var(--border-card-radius) var(--border-card-radius) 0 0;
        }
    }

    &:focus-within {
        /*  Overriding focus styles since this is using ClickArea. */
        .targetLink {
            outline: none;
        }
        .targetButton {
            outline: none;
        }
    }

    @media screen and (--mq-max-width-phone) {
        grid-template-columns: 1fr;

        .imgWrapper {
            min-height: 200px;
        }
    }
}
.content {
    padding: var(--spacing-6);
}

.description {
    margin-top: var(--spacing-3);
    margin-bottom: var(--spacing-6);
}

.imgWrapper {
    align-items: center;
    position: relative;
    border-radius: var(--border-card-radius) 0 0 var(--border-card-radius);
}

.targetLink {
    font-size: var(--font-size-small);
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

.targetButton {
    border: none;
    font-family: var(--typeface-base);
    color: var(--link-color);
    cursor: pointer;
    background: none;
    display: flex;
    padding: 0;
    font-size: var(--font-size-small);
    &:hover {
        color: var(--link-color-hover);
        text-decoration: underline;
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}
