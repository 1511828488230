.root {
    display: inline-flex;
    align-items: center;
    white-space: pre-wrap;
    font: var(--font-text-small);
    font-weight: var(--font-weight-bold);

    color: var(--interactive-color);
    background-color: transparent;
    box-shadow: 0 0 0 0 rgba(0, 124, 137, 0);
    border-radius: var(--border-pill-radius);
    text-decoration: none;
    line-height: calc(var(--font-size-h4));
    transition:
        background-color var(--animation-speed-slow) var(--animation-curve),
        box-shadow var(--animation-speed-slow) var(--animation-curve),
        color var(--animation-speed-slow) var(--animation-curve);

    &:focus {
        outline: calc(var(--base-unit) * 0.5) solid var(--border-interactive-color);
        outline-offset: var(--spacing-1);
    }

    &:hover {
        background-color: var(--bg-interactive-color);
        box-shadow: 0 0 0 6px var(--bg-interactive-color);
    }

    &:hover,
    &:focus,
    &:active {
        color: var(--interactive-hover-color);
        transition:
            background-color var(--animation-speed-fast) var(--animation-curve),
            box-shadow var(--animation-speed-fast) var(--animation-curve),
            color var(--animation-speed-fast) var(--animation-curve);
    }

    &[disabled] {
        pointer-events: none;
        color: var(--disabled-color);
        background-color: transparent;
    }
}

/* Mimic Button / Plain variant */
:global(.mcds-redesign-molecules) .root {
    font-weight: var(--font-weight-component);
    border-radius: var(--radius-action);
    font-size: var(--font-size-component-small);
    line-height: var(--line-height-component);

    &:focus,
    &:hover {
        background: var(--color-action-passive-subtle-hover);
        box-shadow: 0 0 0 4px var(--color-action-passive-subtle-hover);
        outline: none;
    }

    &:active {
        background: var(--color-action-passive-subtle-active);
        box-shadow: 0 0 0 4px var(--color-action-passive-subtle-active);
    }

    /* To create a 1px difference for the 4px box-shadow */
    &:focus-visible {
        outline: 2px solid var(--color-ui-primary);
        outline-offset: 5px;
    }
}
