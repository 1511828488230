/* stylelint-disable css-modules/no-global-scoped-selector */

:root {
    --bg-opacity: 0.5;
    --content-maxWidth: 50rem;
}

/* Root */
.root {
    padding: var(--spacing-8) 0;
    text-align: center;

    &.cornflower {
        background-color:
            rgba(
                197,
                219,
                242,
                var(--bg-opacity)
            ); /* cornflower 50% */
    }
    &.cucumber {
        background-color: var(--bg-success-color);
    }
    &.coconut {
        background-color: rgba(255, 255, 255, var(--bg-opacity)); /* coconut 50% */
    }
    &.radish {
        background-color: rgba(249, 224, 250, var(--bg-opacity)); /* radish 50% */
    }
    &.squash {
        background-color: rgba(251, 238, 202, var(--bg-opacity)); /* squash 50% */
    }
    &.yam {
        background-color: rgba(251, 207, 189, var(--bg-opacity)); /* yam 50% */
    }
    &.jasmine {
        background-color: rgba(246, 246, 244, var(--bg-opacity)); /* jasmine 50% */
    }

    /* Secondary */
    .secondary {
        max-width: var(--content-maxWidth);
    }

    &.isBottomOverlap + * {
        /* Gives any adjacent siblings a default top padding */

        /* to avoid overlap with content. */
        @media screen and (--mq-max-width-tablet) {
            padding-top: var(--spacing-8);
        }
    }
}

/* Title */
.title {
    line-height: var(--line-height-reset);
    max-width: 85rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (--mq-min-width-tablet) {
        font-size: var(--font-size-h1);
    }

    .narrow & {
        max-width: var(--content-maxWidth);
    }
}

/* Secondary */
.secondary {
    margin-left: auto;
    margin-right: auto;
    color: var(--text-secondary-color);
}

/* Actions */
.actions {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 70rem;
}

/* Bottom */
.bottom {
    margin-top: var(--spacing-7);

    .isBottomOverlap & {
        margin-bottom: calc(-1 * var(--spacing-10));
        z-index: var(--z-base);
    }

    /* Make img responsive if present. */
    img {
        @media screen and (--mq-max-width-tablet) {
            width: 100%;
        }
    }
}
