:global(.mcds-redesign-molecules) {
    .root {
        max-width: var(--readable-max-width);

        fieldset > & {
            font: var(--font-size-component-small);
        }

        input {
            /* Input styles are visually implemented on the inputWrapper */
            border: none;
            flex: 1;
            line-height: var(--line-height-component);
            width: 100%;
            outline: 1px solid transparent;
            background: transparent;
            padding: var(--space-component-stack-padding-large, 8px) 0 var(--space-component-stack-padding-large, 8px) 0;

            &::placeholder {
                color: var(--color-text-quaternary);
            }

            &::-ms-clear {
                display: none;
            }
        }
    }

    .searchInputWrapper {
        font: var(--font-family-component);
        width: 100%;
        display: flex;
        align-items: center;
        vertical-align: middle;
        border: 1px solid var(--color-input-border-primary);
        color: var(--color-text-primary);
        background-color: var(--color-input-background-primary);
        border-radius: var(--radius-small);
        padding: var(--space-component-stack-padding-large) var(--space-component-inline-padding-large);
        box-sizing: border-box;

        /* Mobile Safari workaround */
        background-clip: padding-box;

        &:not(.disabledSearchInput):not(:focus-within):hover {
            border-color: var(--color-input-border-primary-hover);
            background-color: var(--color-input-background-primary-hover);
        }

        &:focus-within {
            outline: 1px solid var(--color-input-border-primary-focus);
            border-color: var(--color-input-border-primary-focus);
            background-color: var(--color-input-background-primary-focus);

            /* Mobile Safari workaround */
            box-shadow: inset 0 -2px 0 transparent;
        }

        &.large {
            max-height: var(--space-x-large);
            font-size: var(--font-size-component-small);
            height: calc(var(--base-unit) * 10);
        }

        &.small,
        small input {
            max-height: var(--space-container-padding-x-large);
            font-size: var(--font-size-component-x-small);
            padding: var(--space-component-stack-padding-medium) var(--space-component-inline-padding-medium);
            height: calc(var(--base-unit) * 8);
        }

        svg {
            color: var(--color-icon-primary);
        }
    }

    .searchInputPrefix {
        display: flex;
        padding-right: var(--space-component-gap-small);
    }

    .searchInputSuffix {
        display: flex;
        padding-left: var(--space-component-gap-small);

        svg {
            color: var(--color-icon-secondary);
        }
    }

    .disabledSearchInput,
    .disabledSearchInput input {
        cursor: not-allowed;
        background-color: var(--color-input-background-disabled);
        border-color: var(--color-input-border-disabled);
        color: var(--color-text-disabled);

        svg {
            color: var(--color-icon-disabled);
        }
    }
}
