.breadcrumbs {
    font: var(--font-text-small);

    li {
        display: inline-block;

        &::before {
            content: "/";
            margin: 0 var(--spacing-1);
        }

        &:first-child::before {
            content: "";
            margin: 0;
        }

        &[aria-current] {
            display: none;
        }
    }
}

.breadcrumb {
    color: var(--interactive-color);
}

.breadcrumb:hover {
    text-decoration: underline;
}

.breadcrumb:focus {
    color: var(--interactive-color);
    outline: calc(var(--base-unit) * 0.5) solid var(--border-interactive-color);
    outline-offset: var(--spacing-1);
}
