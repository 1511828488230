/* File allow-listed for globally scoped CSS. Reach out to #fep-team with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

:root {
    /*  Variants */
    --badge-bg--default: var(--bg-info-color);
    --badge-bg--active: var(--bg-active-color);
    --badge-bg--new: var(--bg-status-color);
    --badge-bg--success: var(--bg-success-color);
    --badge-bg--warning: var(--bg-warning-color);
    --badge-bg--error: var(--bg-error-color);
    --badge-text-color: var(--text-primary-color);
    --badge-border: 0 solid transparent;
    --badge-font-weight: var(--font-weight-normal);
    --badge-text-transform: none;
}

:global(.mcds-redesign-molecules) {
    --badge-bg--default: var(--color-action-passive-hover);
    --badge-bg--active: var(--color-ui-discover);
    --badge-bg--new: var(--color-ui-new);
    --badge-bg--success: var(--color-ui-positive);
    --badge-bg--warning: var(--color-ui-attention);
    --badge-bg--error: var(--color-ui-negative);
    --badge-bg--info: var(--color-ui-info);
    --badge-text-color: var(--color-text-inverse);
    --border-badge-radius: var(--radius-small);
}

.root {
    display: inline-block;
    font: var(--font-text-small);
    font-weight: var(--badge-font-weight);
    text-transform: var(--badge-text-transform);
    padding: calc(var(--spacing-1) / 2) var(--spacing-2);
    color: var(--badge-text-color);
    border-radius: var(--border-badge-radius);
    border: var(--badge-border);
}

.default { background-color: var(--badge-bg--default); }
.new { background-color: var(--badge-bg--new); }
.active { background-color: var(--badge-bg--active); }
.success { background-color: var(--badge-bg--success); }
.warning { background-color: var(--badge-bg--warning); }
.error { background-color: var(--badge-bg--error); }

:global(.mcds-redesign-molecules) {
    .root {
        padding: var(--space-component-stack-padding-x-small) var(--space-component-inline-padding-small);
        font-size: var(--font-size-component-x-small);
        display: inline-flex;
        gap: var(--space-component-inline-padding-x-small);
        align-items: center;
        height: calc(var(--base-unit) * 5);
        line-height: var(--line-height-component);
    }

    /* New variants */
    .secondary {
        --badge-text-color: var(--color-text-primary);
        --badge-bg--default: var(--color-container-background-neutral);
        --badge-bg--active: var(--color-container-background-accent);
        --badge-bg--new: var(--color-container-background-new);
        --badge-bg--success: var(--color-container-background-positive);
        --badge-bg--warning: var(--color-container-background-attention);
        --badge-bg--error: var(--color-container-background-negative);
        --badge-bg--info: var(--color-container-background-info);
    }
    .default {
        --badge-text-color: var(--color-text-secondary);
    }
    .info {
        background-color: var(--badge-bg--info);
    }
}
