.root {
    --thumb-color: var(--border-dark-color);
    --thumb-color-active: var(--border-color);
    --thumb-color-disabled: var(--bg-lvl-3-color);
    --track-color: var(--bg-lvl-4-color);
    --border-radius: calc(var(--track-height) / 2);
    --thumb-size: 16px;
    --thumb-click-size: 24px; /*  Accessibility area */
    --track-height: 6px;
    --thumb-fake: 0 0 0 ((calc(var(--thumb-size) - var(--track-height))) / 2) var(--thumb-color);
    --thumb-fake-disabled: 0 0 0 ((calc(var(--thumb-size) - var(--track-height))) / 2) var(--thumb-color-disabled);
    --thumb-margin: -6px;

    max-width: var(--readable-max-width);

    fieldset > & {
        font: var(--font-text-small);
    }

    output {
        color: var(--text-secondary-color);
    }

    input {
        cursor: pointer;
        appearance: none;
        width: 100%;
        cursor: pointer;
        background: transparent;
        box-sizing: border-box;

        /*  Mobile Safari workaround */
        background-clip: padding-box;

        /* https://css-tricks.com/sliding-nightmare-understanding-range-input/ */
        margin: 0;
        padding: 0;
        font-size: inherit;

        /*
        * Within -webkit- implementations of `<input type="range" />, there is a container
        * 'div' that we can target in order to adjust the clickable area for the slider without impacting the rest of the styles
        *
        * For -moz- implementations, there is no container div, and the `<input />` element itself is the container
        */


        /*  Webkit */
        &::-webkit-slider-container {
            min-block-size: var(--thumb-click-size);
        }

        /*
        * Base thumb styles.
        *
        * The Slider's thumb is the little knob that users move around either
        * via mouse or arrow keys.
        *
        * There is some difficulty with styling thumbs. One big one is cross-
        * browser compatibility. Some browsers have the range input's width
        * include the thumb's width, others do not. So we make the thumb as
        * small as it possibly can be, then use box-shadow to fake the rest.
        *
        * Padding and margins on the input range also affect rendering depending
        * on the browser. It's kind of a mess. See this for more:
        * https://css-tricks.com/sliding-nightmare-understanding-range-input/
        */


        /*  Webkit */
        &::-webkit-slider-thumb {
            width: var(--thumb-size);
            height: var(--thumb-size);
            box-shadow: var(--thumb-fake);
            border: none;
            border-radius: 50%;
            background-color: var(--thumb-color);
            transition: box-shadow 0.2s linear;
            -webkit-appearance: none;

            /*  Center thumb vertically */
            margin-top: var(--thumb-margin);
        }


        /*  Firefox */
        &::-moz-range-thumb {
            width: var(--track-height);
            height: var(--track-height);
            box-shadow: var(--thumb-fake);
            border: none;
            border-radius: 50%;
            background-color: var(--thumb-color);
            transition: box-shadow 0.2s linear;
        }


        /*  MSEdge */
        &::-ms-thumb {
            width: var(--track-height);
            height: var(--track-height);
            box-shadow: var(--thumb-fake);
            border: none;
            border-radius: 50%;
            background-color: var(--thumb-color);
            transition: box-shadow 0.2s linear;

            /*  Center thumb vertically (differently than -webkit-) */
            margin-top: var(--thumb-margin);
        }


        /**
         * Input interaction styles
         */


        /*  Hover styles */
        &:hover:not(:disabled):not(:focus) {
            &::-webkit-slider-thumb { box-shadow: var(--thumb-fake), 0 0 0 10px var(--thumb-color-active); }
            &::-moz-range-thumb { box-shadow: var(--thumb-fake), 0 0 0 10px var(--thumb-color-active); }
            &::-ms-thumb { box-shadow: var(--thumb-fake), 0 0 0 10px var(--thumb-color-active); }
        }


        /*  Focus styles */
        &:focus {
            outline: none;

            &::-webkit-slider-thumb {
                /*  For Windows High Contrast Mode */
                outline: 2px solid transparent;
                box-shadow: var(--thumb-fake), 0 0 0 16px var(--thumb-color-active);
            }
            &::-moz-range-thumb {
                /*  For Windows High Contrast Mode */
                outline: 2px solid transparent;
                box-shadow: var(--thumb-fake), 0 0 0 16px var(--thumb-color-active);
            }
            &::-ms-thumb {
                /*  For Windows High Contrast Mode */
                outline: 2px solid transparent;
                box-shadow: var(--thumb-fake), 0 0 0 16px var(--thumb-color-active);
            }
        }


        /*  Disabled styles */
        &:disabled {
            cursor: not-allowed;
            outline: none;

            &::-webkit-slider-thumb {
                background: var(--thumb-color-disabled);
                box-shadow: var(--thumb-fake-disabled);
            }
            &::-moz-range-thumb {
                background: var(--thumb-color-disabled);
                box-shadow: var(--thumb-fake-disabled);
            }
            &::-ms-thumb {
                background: var(--thumb-color-disabled);
                box-shadow: var(--thumb-fake-disabled);
            }
        }

        /*
        * Base track styles.
        *
        * The Slider's track is the line that runs the length of the slider.
        * Note that we style the peppercorn bit via a separate div.
        */


        /*  Webkit */
        &::-webkit-slider-runnable-track {
            width: 100%;
            height: var(--track-height);
            background: var(--track-color);
            border-radius: var(--border-radius);
        }


        /*  Firefox */
        &::-moz-range-track {
            width: 100%;
            height: var(--track-height);
            background: var(--track-color);
            border-radius: var(--border-radius);
        }


        /*  MSEdge */
        &::-ms-track {
            width: 100%;
            height: var(--track-height);
            background: var(--track-color);
            border-radius: var(--border-radius);
            border-color: transparent;
            color: transparent;
        }

        &::-ms-fill-lower,
        &:focus::-ms-fill-lower,
        &::-ms-fill-upper,
        &:focus::-ms-fill-upper {
            background: var(--track-color);
        }

        /**
         * Native tooltips.
         */
        &::-ms-tooltip { display: none; }
    }
}

.before {
    display: flex;
    justify-content: space-between;


    /*  Add bottom margin to `.before`'s direct children. If elements before the */

    /*  input are visually empty, no margin should display before the input. */
    > * {
        margin-bottom: var(--spacing-2);
    }
}

.rangeContainer {
    display: flex;
    align-items: center;
    width: 100%;

    > svg {
        width: 24px;
        height: 24px;
    }
}

.withAnchorIcons {
    margin: 0 var(--spacing-2);
}

.range {
    position: relative;
    display: block;
    width: 100%;
    height: var(--thumb-size);
    display: flex;
    align-items: center;
    margin: 0 (calc(var(--thumb-size) / 2));
}

.track {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    height: var(--track-height);
    background-color: var(--track-color);
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    z-index: -1;
}

.ticks {
    pointer-events: none;
    width: 100%;
    position: absolute;
    left: 0;
    height: var(--track-height);
    display: flex;
    justify-content: space-between;
    transform: translateY(var(--thumb-size));
}

.tick {
    background-color: var(--border-dark-color);
    width: var(--track-height);
    height: var(--track-height);
    border-radius: 50%;
    transform: translateY(calc(calc(-1 * var(--thumb-size))));
}

.helpText {
    margin-top: var(--spacing-2);
    color: var(--text-secondary-color);
}

.errorMessage {
    margin-top: var(--spacing-2);
    color: var(--text-error-color);
}
