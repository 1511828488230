.container {
    border-bottom: 1px solid var(--border-light-color);
    position: sticky;
    top: 0;
    z-index: var(--z-header); /*  inside `Page` component stacking context */
}

.header {
    background-color: var(--bg-lvl-1-color);
    height: 80px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;

    &.focusMode {
        padding-left: calc(var(--base-unit) * 25);
    }

    .children {
        flex: 1 1 auto;
    }

    .actions {
        flex: 0 0 auto;
    }

    @media screen and (--mq-max-width-phone) {
        padding: 0 var(--spacing-4);

        &.focusMode {
            padding-left: 80px;
        }
    }
}

.titleMenuContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-4);
    margin-right: var(--spacing-2);
}

.titleOverflowEllipsisCluster {
    overflow: hidden;
}

.titleOverflowEllipsisStack {
    min-width: 0;
}

.titleOverflowEllipsisHeading {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
