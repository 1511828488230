/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

@import (reference) './tokens.less';
@import (inline) '@mc/design-tokens/dist/tokens.custom-properties.css';
@import (less) './_ids-tokens.css'; // Bypass esbuild:css build step with (less). Remove when esbuild has been fully deprecated

/*********************************************
    THEME VARIABLES
    CSS variables shared across components. These variables may be adjusted
    according to the current theme.
*********************************************/
:root {
    // MCDS's dependent on rems, which is problematic because In-app uses a
    // non-standard font-size. Dotcom has a font-size of 100%, so rems are
    // different on Dotcom than in In-app. This font-ratio variable can be
    // adjusted on dotcom/in-app so that MCDS's internal font sizes can
    // adjust accordingly
    // Font size multiplier.
    --font-ratio: 1;

    // The hardcoded values defined here are based on the values coming from
    // the @mc/design-tokens/dist/tokens.less file.
    // MCDS's dependent on rems, which is problematic because In-app uses a
    // non-standard font-size. Dotcom has a font-size of 100%, so rems are
    // different on Dotcom than in In-app. This font-ratio variable can be
    // adjusted on dotcom/in-app so that MCDS can cheat on rem calculations

    --font-size-display-1: calc(8.4rem * var(--font-ratio));
    --font-size-display-2: calc(7.2rem * var(--font-ratio));
    --font-size-display-3: calc(6rem * var(--font-ratio));
    --font-size-display-4: calc(4.8rem * var(--font-ratio));
    --font-size-h1: calc(4.8rem * var(--font-ratio));
    --font-size-h2: calc(4rem * var(--font-ratio));
    --font-size-h3: calc(3.4rem * var(--font-ratio));
    --font-size-h4: calc(2.8rem * var(--font-ratio));
    --font-size-h5: calc(2.4rem * var(--font-ratio));
    --font-size-h6: calc(2rem * var(--font-ratio));
    --font-size-tiny: calc(1.1rem * var(--font-ratio));
    --font-size-xsmall: calc(1.2rem * var(--font-ratio));
    --font-size-small: calc(1.4rem * var(--font-ratio));
    --font-size-medium: calc(1.6rem * var(--font-ratio));
    --font-size-large: calc(2rem * var(--font-ratio));

    /*----------------------------------------------------------------
        The line height value is the first value in side of the parentheses
        The line heights values were defined by this mixin

        Vertical baseline line-height mixin
        For a given font-size (rem) and a unitless line-height
        find the smallest integer value for line-height (rem),
        which falls on the baseline grid

        @param font-size Font size in rem
        @param line-height Line height, unitless
        @param rhythm Vertical rhythm for baseline grid, default 0.4rem (4px)

        .line-height(@font-size, @line-height: 1, @rhythm: @base-unit-rem) {
            line-height: ceil(@font-size * (@line-height / @rhythm)) * @rhythm;
        }
    *********************************************/

    --line-height-display-1: calc(9.6rem * var(--font-ratio));
    --line-height-display-2: calc(8.4rem * var(--font-ratio));
    --line-height-display-3: calc(6.8rem * var(--font-ratio));
    --line-height-display-4: calc(5.6rem * var(--font-ratio));
    --line-height-h1: calc(5.6rem * var(--font-ratio));
    --line-height-h2: calc(4.8rem * var(--font-ratio));
    --line-height-h3: calc(4.4rem * var(--font-ratio));
    --line-height-h4: calc(3.6rem * var(--font-ratio));
    --line-height-h5: calc(3.2rem * var(--font-ratio));
    --line-height-h6: calc(2.8rem * var(--font-ratio));
    --line-height-tiny: calc(1.6rem * var(--font-ratio));
    --line-height-xsmall: calc(1.6rem * var(--font-ratio));
    --line-height-small: calc(2rem * var(--font-ratio));
    --line-height-medium: calc(2.4rem * var(--font-ratio));
    --line-height-large: calc(2.8rem * var(--font-ratio));
    --tag-line-height: calc(1.6rem * var(--font-ratio));

    /*************************
    *  Typography
    *************************/

    /* Letter spacing */
    --letter-spacing-display: -0.02em;
    --letter-spacing-heading: -0.02em;
    --letter-spacing-body: 0;
    --letter-spacing-body-bold: -0.01em;
    --letter-spacing-component: 0;
    --letter-spacing-component-bold: -0.01em;

    --font-display-1:
        var(--font-size-display-1) / var(--line-height-display-1)
        var(--typeface-brand);
    --font-display-2:
        var(--font-size-display-2) / var(--line-height-display-2)
        var(--typeface-brand);
    --font-display-3:
        var(--font-size-display-3) / var(--line-height-display-3)
        var(--typeface-brand);
    --font-display-4:
        var(--font-size-display-4) / var(--line-height-display-4)
        var(--typeface-brand);
    // Value for CSS font shorthand property. Heading 1 style in Graphik.
    --font-heading-1:
        var(--font-size-h1) / var(--line-height-h1)
        var(--typeface-base);
    // Value for CSS font shorthand property. Heading 2 style in Graphik.
    --font-heading-2:
        var(--font-size-h2) / var(--line-height-h2)
        var(--typeface-base);
    // Value for CSS font shorthand property. Heading 3 style in Graphik.
    --font-heading-3:
        var(--font-size-h3) / var(--line-height-h3)
        var(--typeface-base);
    // Value for CSS font shorthand property. Heading 4 style in Graphik.
    --font-heading-4:
        var(--font-size-h4) / var(--line-height-h4)
        var(--typeface-base);
    // Value for CSS font shorthand property. Heading 5 style in Graphik.
    --font-heading-5:
        var(--font-size-h5) / var(--line-height-h4)
        var(--typeface-base);
    // Value for CSS font shorthand property. Heading 6 style in Graphik.
    --font-heading-6:
        var(--font-size-h6) / var(--line-height-h4)
        var(--typeface-base);
    // Value for CSS font shorthand property. Large font size in Graphik with a line-height of 2.8rem.
    --font-text-large:
        var(--font-size-large) / var(--line-height-large)
        var(--typeface-base);
    // Value for CSS font shorthand property. Medium font size in Graphik with a line-height of 2.4rem.
    --font-text-medium:
        var(--font-size-medium) / var(--line-height-medium)
        var(--typeface-base);
    // Value for CSS font shorthand property. Small font size in Graphik with a line-height of 2rem.
    --font-text-small:
        var(--font-size-small) / var(--line-height-small)
        var(--typeface-base);
    --font-text-xsmall:
        var(--font-size-xsmall) / var(--line-height-xsmall)
        var(--typeface-base);
    // Value for CSS font shorthand property. Tiny font size in Graphik with a line-height of 1.6rem.
    --font-text-tiny:
        var(--font-size-tiny) / var(--line-height-tiny)
        var(--typeface-base);

    // Font weight
    --font-display-weight: var(--font-weight-normal);
    --font-heading-weight: var(--font-weight-bold);
}

:root {
    /*************************
    *  Interactive
    *************************/

    // The color used to show a component is interactive.
    --interactive-color: var(--color-ui-primary);
    // Used when the user hovers over the interactive color (if applicable).
    --interactive-hover-color: var(--color-ui-primary-hover);
    // The interactive color on inverted backgrounds.
    --interactive-inverted-color: var(--color-text-inverse);
    // The common disabled color for backgrounds, or text.
    --disabled-color: var(--color-text-disabled);
    // The common disabled color for buttons. DEPRECATED
    --disabled-button-color: var(--brand-peppercorn-15);
    // The common disabled opacity for buttons.
    --disabled-button-opacity: 40%;
    // The common disabled color for borders. DEPRECATED
    --disabled-border-color: var(--brand-peppercorn-15);

    /*************************
    *  Backgrounds
    *************************/

    // The deepest background color. Usually the page background color.
    --bg-lvl-1-color: var(--color-page-background-primary);
    // One level up from the deepest background color.
    --bg-lvl-2-color: var(--color-page-background-secondary);
    // Two levels up from the deepest background color.
    --bg-lvl-3-color: var(--color-page-background-tertiary);
    // The shallowest background color.
    --bg-lvl-4-color: var(--color-page-background-accent);

    // Used for the page's base color.
    --bg-page-color: var(--color-page-background-primary);
    // Used for inverted page's base color.
    --bg-inverted-page-color: var(--color-ui-secondary-active);
    // Used for modals and bottom sheet.
    --bg-underlay-color: var(--color-overlay);
    // Used when the user interacts with a control in some way: hover, selection, focus, etc.
    --bg-interactive-color: var(--agave-80-10);
    // Inverted colors are used for selected items in a menu.
    --bg-inverted-color: var(--color-ui-primary);
    // Background color for the success status.
    --bg-success-color: var(--color-container-background-positive);
    // Background color for the error status.
    --bg-error-color: var(--color-container-background-negative);
    // Background color for the warning status.
    --bg-warning-color: var(--color-container-background-attention);
    // Background color for the info status.
    --bg-info-color: var(--color-container-background-neutral);
    --bg-active-color: var(--color-container-background-accent);
    --bg-status-color: var(--color-container-background-new);

    /*************************
    *  Text
    *************************/

    // Text color used in regular body copy.
    --text-primary-color: var(--color-text-primary);
    // Text color used in secondary copy.
    --text-secondary-color: var(--color-text-secondary);
    // Text color used in inverted backgrounds.
    --text-inverted-color: var(--color-text-inverse);
    // Text color used in headlines.
    --text-headline-primary-color: var(--color-text-primary);

    // Text color used for success statuses
    --text-success-color: var(--color-ui-positive);
    // Text color used for errors
    --text-error-color: var(--color-ui-negative);

    /* Text color used for warnings */
    --text-warning-color: var(--color-ui-attention);

    /*************************
    *  Borders
    *************************/

    // Medium-weight border color.
    --border-color: var(--color-container-border-tertiary);
    // Lightweight border color.
    --border-light-color: var(--color-container-border-primary);
    // Heavyweight border color.
    --border-dark-color: var(--color-input-border-secondary);
    // Border color used for interactive elements. Typically is the focus style.
    --border-interactive-color: var(--color-container-border-accent);

    // Border color around input components.
    --border-input-color: var(--color-input-border-primary);
    // Border color around input components on hover/focus.
    --border-input-color-hover: var(--color-input-border-primary-hover);

    // Border radius for input components.
    --border-input-radius: var(--radius-small);
    // Border radius for badges and tags.
    --border-badge-radius: var(--radius-small);
    // Border radius for accordion and table.
    --border-data-radius: var(--radius-medium);
    // Border radius for overlay UI (menu, toast, popover).
    --border-overlay-radius: var(--radius-medium);
    // Border radius for modals and cards.
    --border-card-radius: var(--radius-large);
    // Border radius for buttons.
    --border-pill-radius: var(--radius-full);
    // Border radius for avatars.
    --border-avatar-radius: var(--radius-full);

    // Border color for error messages and input boxes with errors.
    --border-error-color: var(--color-ui-negative);
    // Border color for warning messages.
    --border-warning-color: var(--color-ui-attention);
    // Border color for success messages.
    --border-success-color: var(--color-ui-positive);

    /*************************
    *  Toggles
    *************************/

    // Background color for toggles.
    --bg-toggle-color: var(--color-input-background-secondary);
    // Background color for activated toggles.
    --bg-toggle-activated-color: var(--color-input-background-primary-selected);
    // Border color for toggles.
    --border-toggle-color: var(--color-input-background-secondary);
}

:root {
    /******
    * MCDS REDESIGN GLOBAL TOKENS (DEPRECATED)
    * New design foundation for MCDS refresh project. This project will default using IDS semantic tokens,
    * however, this is to replace MCDS global tokens used directly.
    * IDS tokens are defined in web/js/src/@mc/wink/styles/_ids-tokens.css
    ********/


    /* Border Radius Global Tokens (to replace mc/design-tokens) */
    --border-radius-lv0: var(--radius-none);
    --border-radius-lv1: var(--radius-x-small);
    --border-radius-lv2: var(--radius-small);
    --border-radius-lv3: var(--radius-medium);
    --border-radius-lv4: var(--radius-large);
    --border-radius-lv5: var(--radius-full);
    --border-radius-lv6: 50px; /* deprecated */


    /* Elevation Primitive Tokens (to replace mc/design-tokens) */
    --elevation-shadow:
        rgba(
            76,
            85,
            91,
            var(--opacity-shadow)
        ); /* pepper-90 at 20% */
    --elevation-lvl-4: 0 6px 24px 0 var(--elevation-shadow);
    --elevation-lvl-3: 0 4px 16px 0 var(--elevation-shadow);
    --elevation-lvl-2: 0 2px 8px 0 var(--elevation-shadow);
    --elevation-lvl-1: 0 2px 2px 0 var(--elevation-shadow);
    --elevation-lvl-0: none;

    /**************
    * DEPRECATED COLOR PALETTE
    * These are our legacy primitive tokens. Since many places in the app use them directly,
    * we are swapping them for the redesign equivalent to ensure color updates are applied globally.
    * These values are from IDS primitive tokens.
    ***********************/

    --brand-peppercorn: #181c1f; /* pepper-130 */
    --brand-oyster: #3c4348; /* pepper-100 */
    --brand-tuna: #859299; /* pepper-60 */
    --brand-portobello: #9aa7b0; /* pepper-50 */
    --brand-basmati: #c3ced5; /* pepper-30 */
    --brand-parsnip: #e2e9ed; /* pepper-10 */
    --brand-jasmine: #f0f4f6; /* pepper-0 */

    --brand-peppercorn-65: rgba(24, 28, 31, 0.65); /* Pepper-a65 */
    --brand-peppercorn-30: rgba(24, 28, 31, 0.3); /* Pepper-a30 */
    --brand-peppercorn-15: rgba(24, 28, 31, 0.15); /* Pepper-a15 */

    --brand-cornflower: #c5e1ff; /* blueberry-20 */
    --brand-cucumber: #d8ffdb; /* kiwi-10 */
    --brand-squash: #ffeac7; /* persimmon-10 */
    --brand-yam: #ffd4d8; /* watermelon-20 */
    --brand-radish: #f0e9ff; /* eggplant-10 */

    --brand-dragonfruit: #f54bac; /* dragonfruit-60 */
    --brand-apple: #00bb42; /* kiwi-60 */
    --brand-apple-accessible: #00892e; /* kiwi-80 */
    --brand-pumpkin: #e56c1d; /* persimmon-60 */
    --brand-pumpkin-accessible: #a53900; /* persimmon-80 */
    --brand-plum: #4d0031; /* dragonfruit-110 */
    --brand-apricot: #d99001; /* honey-60 */
    --brand-apricot-accessible: #d99001; /* honey-60 */

    --brand-kale: #00828d; /* agave-80 */
    --brand-kale-light: #00d0e0; /* agave-50 */
    --brand-kale-dark: #005056; /* agave-100 */
}

/*********************************************
    DARK MODE
    CSS variables for dark mode.
*********************************************/

:global(.dark-mode) {
    --interactive-color: var(--brand-kale-light);
    --interactive-hover-color: #36a6a4; // kale light darken by 10%
    --interactive-inverted-color: var(--brand-coconut);
    --disabled-color: var(--brand-coconut-50);
    --disabled-button-color: var(--brand-coconut-20);
    --disabled-border-color: var(--brand-coconut-20);

    --bg-lvl-1-color: var(--brand-peppercorn);
    --bg-lvl-2-color: #2b241f; // compiled value of mix(@brand-peppercorn, @brand-oyster, 75%);
    --bg-lvl-3-color: #322c28; // compiled value of mix(@brand-peppercorn, @brand-oyster, 50%);
    --bg-lvl-4-color: #393332; // compiled value of mix(@brand-peppercorn, @brand-oyster, 25%);

    --bg-page-color: var(--bg-lvl-1-color);
    --bg-underlay-color: rgba(0, 0, 0, 0.7);
    --bg-interactive-color: rgba(75, 196, 194, 0.2); // kale light at 20%
    --bg-inverted-color: var(--brand-kale);
    --bg-success-color: var(--brand-cucumber);
    --bg-error-color: var(--brand-yam);
    --bg-warning-color: var(--brand-squash);
    --bg-info-color: var(--brand-cornflower);

    --elevation-lvl-1:
        0 0 0 1px rgba(255, 255, 255, 0.1),
        0 4px 12px rgba(0, 0, 0, 0.12);
    --elevation-lvl-2:
        0 0 0 1px rgba(255, 255, 255, 0.1),
        0 12px 24px rgba(0, 0, 0, 0.16);
    --elevation-lvl-3:
        0 0 0 1px rgba(255, 255, 255, 0.1),
        0 16px 24px rgba(0, 0, 0, 0.24);

    --text-primary-color: var(--brand-coconut);
    --text-secondary-color: var(--brand-coconut-50);
    --text-inverted-color: var(--brand-coconut);
    --text-headline-primary-color: var(--brand-coconut);

    --text-success-color: var(--brand-apple-accessible);
    --text-error-color: var(--brand-pumpkin-accessible);

    --border-color: var(--brand-coconut-50);
    --border-light-color: var(--brand-oyster);
    --border-dark-color: var(--brand-coconut);
    --border-interactive-color: var(--interactive-color);
    --border-input-color: var(--brand-coconut-50);
    --border-error-color: var(--text-error-color);

    --bg-toggle-color: var(--bg-lvl-2-color);
    --bg-toggle-activated-color: var(--brand-kale);
    --border-toggle-color: var(--border-color);
}

:global(.dark-mode),
:global(.dark-mode) * {
    scrollbar-width: thin;
    scrollbar-color: var(--bg-lvl-3-color) var(--bg-lvl-1-color);

    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--bg-lvl-4-color);
    }
    &::-webkit-scrollbar-track {
        background: var(--bg-lvl-1-color);
    }
}

/**************
    * MCDS REDESIGN - MILESTONE 2 FLAG
    * Updates related to our second release of MCDS Redesign
    ***********************/

:global(.mcds-redesign-molecules) {
    /* Updating line-height for all copy */
    --letter-spacing-display: -0.01rem;
    --letter-spacing-heading: -0.05rem;
    --letter-spacing-body: 0;
    --letter-spacing-body-bold: -0.02rem;
    --letter-spacing-component: 0;
    --letter-spacing-component-bold: -0.02rem;

    --line-height-display-1: calc(var(--line-height-display) * var(--font-ratio));
    --line-height-display-2: calc(var(--line-height-display) * var(--font-ratio));
    --line-height-display-3: calc(var(--line-height-display) * var(--font-ratio));
    --line-height-display-4: calc(var(--line-height-display) * var(--font-ratio));
    --line-height-h1: calc(var(--line-height-heading) * var(--font-ratio));
    --line-height-h2: calc(var(--line-height-heading) * var(--font-ratio));
    --line-height-h3: calc(var(--line-height-heading) * var(--font-ratio));
    --line-height-h4: calc(var(--line-height-heading) * var(--font-ratio));
    --line-height-h5: calc(var(--line-height-heading) * var(--font-ratio));
    --line-height-h6: calc(var(--line-height-heading) * var(--font-ratio));
    --line-height-tiny: calc(var(--line-height-body) * var(--font-ratio));
    --line-height-xsmall: calc(var(--line-height-body)* var(--font-ratio));
    --line-height-small: calc(var(--line-height-body) * var(--font-ratio));
    --line-height-medium: calc(var(--line-height-body) * var(--font-ratio));
    --line-height-large: calc(var(--line-height-body) * var(--font-ratio));
}
