.fieldset {

    & > :not(:last-child) {
        margin-bottom: var(--spacing-4);
    }
}

.required {
    color: var(--text-secondary-color);
}
