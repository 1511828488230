/*------------------------------------*\
  #Card Root
\*------------------------------------*/

.root {
    /*  Keeps the height from expanding when a sibling `Card` has more content (when side by side). */
    height: min-content;
    position: relative;
    border-radius: var(--border-card-radius);

    &.default {
        background-color: var(--bg-lvl-1-color);
        border: solid 1px var(--border-light-color);
        transition: var(--box-shadow-transition);

        &:hover {
            box-shadow: var(--elevation-lvl-1);
        }

        &:focus-within {
            box-shadow: 0 0 0 2px var(--border-interactive-color), var(--elevation-lvl-1);
        }
    }
    &.none {
        background-color: initial;
        border: 1px solid transparent;

        .rootImage {
            border-radius: 0;
        }
    }
}

/*------------------------------------*\
  #Image
\*------------------------------------*/

.rootImage {
    margin: 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: var(--border-card-radius) var(--border-card-radius) 0 0;

    /*  Background Color */
    &.coconut {
        background-color: var(--bg-lvl-1-color);
    }

    &.cornflower {
        background-color: var(--bg-info-color);
    }

    &.cucumber {
        background-color: var(--bg-success-color);
    }

    &.radish {
        background-color: var(--brand-radish);
    }

    &.squash {
        background-color: var(--bg-warning-color);
    }

    &.yam {
        background-color: var(--bg-error-color);
    }

    &.jasmine {
        background-color: var(--bg-lvl-2-color);
    }


    /*  Appearance */
    &.basic {
        padding: 0;
    }
    &.default {
        padding: var(--spacing-4);
    }
    &.medium {
        padding: var(--spacing-6);
    }
}

.img {
    overflow: hidden;
    display: block;
}

/*  Image becomes a background image when sizing=fill. */
.rootBgImage {
    min-height: 30rem;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
}
