/* stylelint-disable css-modules/no-global-scoped-selector */
:root {
    --tag-line-height: calc(var(--font-ratio) * 1.6rem);
}

.root {
    display: inline-flex;
    background-color: var(--color-ui-tertiary);
    border: 1px solid var(--border-color);
    border-radius: var(--border-badge-radius);
    align-items: center;
    transition: background-color 150ms var(--animation-curve);

    &.interactive {
        &:hover {
            background-color: var(--color-ui-tertiary-active);
            transition: background-color 50ms var(--animation-curve);
        }

        &:focus {
            outline: 2px solid var(--border-interactive-color);
            outline-offset: 1px;
        }
    }

    &.interactive:not(.dismissible) {
        color: var(--interactive-hover-color);
        font-weight: var(--font-weight-bold);
        text-decoration: underline;
    }
}

.small {
    padding: var(--spacing-1) var(--spacing-2);
    font: var(--font-text-small);

    /*  Tag line-heights differ from small text */
    line-height: var(--tag-line-height);
}

.large {
    padding: var(--spacing-1) var(--spacing-4);
    font: var(--font-text-medium);
}

.x-large {
    padding: var(--spacing-1) var(--spacing-2);
    font: var(--font-text-medium);

    .crossIcon {
        height: calc(var(--base-unit) * 6);
        width: calc(var(--base-unit) * 6);
        margin-left: var(--spacing-4);
    }
}

.dismissible {
    /*  Optically balances the close icon. */
    padding-right: var(--spacing-2);
}

.crossIcon {
    height: calc(var(--base-unit) * 4);
    width: calc(var(--base-unit) * 4);
    margin-left: var(--spacing-1);
}

:global(.mcds-redesign-molecules) {
    .root {
        display: flex;
        height: calc(var(--base-unit) * 6);
        padding: var(--space-component-stack-padding-small) var(--space-component-inline-padding-small);
        justify-content: center;
        border: none;
        gap: var(--space-component-gap-small);
        border-radius: var(--radius-small);
        color: var(--color-text-primary);
        font-family: var(--font-family-component);
        font-size: var(--font-size-component-x-small);
        background: var(--color-action-passive);
        font-weight: var(--font-weight-component);
        line-height: var(--line-height-component);
        text-decoration: none;

        &.interactive {
            text-decoration: none;
            border: 1px solid var(--color-container-border-secondary);
            background: var(--color-action-standard-subtle);
            color: var(--color-text-primary);
            font-weight: var(--font-weight-component);

            &:hover {
                background-color: var(--color-action-standard-subtle-hover);
            }

            /* Remove the default focus outline for all focusable elements */
            &:focus {
                outline: none;
            }

            /* Add a custom focus outline only when the element is focused using the keyboard */
            &:focus-visible {
                outline: 2px solid var(--color-focus-indicator);
            }
            &:active {
                background: var(--color-action-standard-subtle-active);
            }
        }
    }
    .dismissible {
        border: 1px solid var(--color-container-border-secondary);
        background: var(--color-action-standard-subtle);
    }

    .disabled {
        border: 1px solid var(--color-container-border-secondary);
        opacity: 0.4;
        background: var(--color-action-standard-subtle);
    }

    .x-large .crossIcon,
    .crossIcon {
        height: calc(var(--base-unit) * 3);
        width: calc(var(--base-unit) * 3);
        color: var(--color-icon-secondary);
        margin-left: 0;
    }
}
