/*------------------------------------*\
    #Marketing Card Root
\*------------------------------------*/

.root {
    &:hover {
        .targetLink {
            text-decoration: underline;
        }
    }

    &:focus-within {
        transition: var(--box-shadow-transition);
        box-shadow: 0 0 0 4px var(--bg-lvl-1-color), 0 0 0 6px var(--border-interactive-color);

        .targetLink {
            outline: none;
            text-decoration: underline;
        }
    }
}

/*  TODO Add responsive layout */
.content {
    padding: var(--spacing-4) 0;
}

.eyebrow {
    text-transform: uppercase;
    font-size: var(--font-size-small);
}

.targetLink {
    font: var(--font-heading-5);
    font-weight: var(--font-heading-weight);
    margin-top: var(--spacing-1);
    margin-bottom: var(--spacing-2);
    text-decoration: none;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}
