.inlineFeedback {
    padding: var(--spacing-4);

    a {
        color: inherit;
    }

    &.success {
        background-color: var(--bg-success-color);
    }
    &.warning {
        background-color: var(--bg-warning-color);
    }
    &.error {
        background-color: var(--bg-error-color);
    }
    &.info {
        background-color: var(--bg-info-color);
    }

    .icon svg {
        width: var(--spacing-6);
        height: var(--spacing-6);
    }
}
