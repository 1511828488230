.root {
    --standard-min-width: 400;
    --standard-max-width: 1192;
    --standard-min-margin: 16;
    --standard-max-margin: 80;

    --wide-min-width: 400;
    --wide-max-width: 1440;
    --wide-min-margin: 16;
    --wide-max-margin: 24;

    --unit: 1px;


    /*  This is roughly equal to 70ch (var(--readable-max-width)) */
    --narrow-max-width: 762;

    margin-left: auto;
    margin-right: auto;
}

.standard {
    /*  Fallback for browsers that don't support clamp() */
    padding: 0 calc(var(--standard-min-margin) * var(--unit));
    padding: 0 calc(((clamp(calc(var(--standard-min-width) * var(--unit)), 100%, calc(var(--standard-max-width) * var(--unit))) - calc(var(--standard-min-width) * var(--unit))) * (calc(var(--standard-max-margin) - var(--standard-min-margin))) / (calc(var(--standard-max-width) - var(--standard-min-width)))) + calc(var(--standard-min-margin) * var(--unit)));
    max-width: calc(var(--standard-max-width) * var(--unit));
}

.narrow {
    padding: 0 calc(var(--standard-min-margin) * var(--unit));
    max-width: calc(var(--narrow-max-width) * var(--unit));
}

.wide {
    /*  Fallback for browsers that don't support clamp() */
    padding: 0 calc(var(--wide-min-margin) * var(--unit));
    padding: 0 calc(((clamp(calc(var(--standard-min-width) * var(--unit)), 100%, calc(var(--standard-max-width) * var(--unit))) - calc(var(--standard-min-width) * var(--unit))) * (calc(var(--standard-max-margin) - var(--standard-min-margin))) / (calc(var(--standard-max-width) - var(--standard-min-width)))) + calc(var(--standard-min-margin) * var(--unit)));
}
