/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

/*  COLOR PICKERS */

/* Overriding default styles of ColorPicker (react-colorful) */

/* With the conversion to CSS, we had to globally style each selector due to CSS module limitations */

/* Referencing GitHub thread - https://github.com/css-modules/css-modules/issues/273 */
:global(.colorSpace) {
    --panel-width: calc(var(--base-unit) * 88);

    :global(.popoverColorSpace) {
        width: calc(var(--panel-width) - 50px);
        height: 120px;

        :global(.react-colorful__saturation) {
            max-width: 100%;
        }

        :global(.react-colorful__hue) {
            height: calc(var(--spacing-7) + 2px);
        }

        :global(.react-colorful__hue-pointer) {
            width: calc(var(--spacing-3) + 1px);
            height: calc(var(--spacing-4) + 1px);
        }
    }

    :global(.react-colorful) {
        width: 100%;
        height: 100%;
    }

    :global(.react-colorful__saturation) {
        border-radius: 0;
        border-bottom: 0;
        max-width: calc(var(--base-unit) * 78); /*  312px */
        height: calc(var(--base-unit) * 60); /*  240px */
    }

    :global(.react-colorful__alpha),
    :global(.react-colorful__hue) {
        margin-top: var(--spacing-4);
        height: calc(var(--base-unit) * 3);
        border-radius: 0;
    }

    :global(.react-colorful__saturation-pointer) {
        width: var(--spacing-4);
        height: var(--spacing-4);
        border: var(--border-radius-lv1) solid var(--interactive-inverted-color);
        box-shadow: 0 0 0 var(--border-radius-lv1) var(--border-input-color);
    }

    :global(.react-colorful__alpha-pointer),
    :global(.react-colorful__hue-pointer) {
        width: var(--spacing-3);
        height: var(--spacing-4);
        border: 1px solid var(--border-light-color);
        border-radius: var(--border-radius-lv1);
        box-shadow: var(--elevation-lvl-2);

        :global(.react-colorful__pointer-fill) {
            /*  Important override because fill is set inline */
            background-color: var(--bg-lvl-1-color) !important;
        }
    }


    /*  Focus styles */
    :global(.react-colorful__interactive) {
        height: 100%;
        &:focus {
            outline: 2px var(--border-interactive-color) solid;
            outline-offset: 1px;
        }
    }


    /*  Hide Hue slider */
    &:global(.hideAlpha) :global(.react-colorful__alpha) {
        display: none;
    }
}
