.container {
    background-color: var(--bg-lvl-2-color);
    padding: var(--spacing-6);

    .button {
        background-color: transparent;
    }
}

.containerWidth {
    max-width: var(--readable-max-width);
}

/** Manually hiding input. wink-visually-hidden class wasn't
working appropriately with :not(:focus) and :not(:active) because of styles needed below */
.fileinput {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
}

/** Cloning Secondary button styles.*/
.upload {
    background-color: transparent;
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    font-size: var(--font-text-small);
    font-weight: var(--font-weight-bold);

    /*  Line-height for button height */
    line-height: calc(var(--base-unit) * 11);
    white-space: nowrap;
    padding: 0 calc(var(--base-unit) * 8);
    user-select: none;
    color: var(--interactive-color);
    box-shadow: inset 0 0 0 1px var(--border-interactive-color);
    border-radius: var(--border-pill-radius);
    text-decoration: none;
    transition:
        border var(--duration-base) ease-in-out 0s,
        color var(--duration-base) ease-in-out 0s,
        background-color var(--duration-base) ease-in-out 0s;
    cursor: pointer;
}

.upload:hover {
    color: var(--interactive-hover-color);
    box-shadow: inset 0 0 0 1px var(--interactive-hover-color);
    background-color: var(--bg-interactive-color);
}


.fileinput:focus + .upload {
    outline: calc(var(--base-unit) * 0.5) solid var(--border-interactive-color);
    outline-offset: 1px;
}

