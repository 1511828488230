.popup {
    padding: var(--spacing-6);
    background: var(--bg-lvl-1-color);
    box-shadow: var(--elevation-lvl-1);
    border-radius: var(--border-overlay-radius);

    transition: transform 0.2s ease-in-out;
    @media (prefers-reduced-motion) {
        transition: none;
    }
}
:global(.mcds-redesign-molecules) {
    .popup {
        padding: var(--space-container-padding-small);
        background: var(--color-container-background-primary);
        box-shadow: var(--elevation-lvl-2);
        border-radius: var(--radius-medium);
        border: var(--color-container-border-primary);
    }
}
