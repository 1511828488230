/* stylelint-disable css-modules/no-global-scoped-selector */
:global(.mcds-redesign-molecules) {
    .root {
        --mcds-iconbutton-border: none;
        --mcds-iconbutton-border-active: none;
        --mcds-iconbutton-border-radius: var(--radius-action);
        --mcds-iconbutton-opacity: var(--opacity-disabled);

        /* Default style for size medium */
        --mcds-iconbutton-padding: var(--space-component-stack-padding-medium) var(--space-component-inline-padding-small);
        --mcds-iconbutton-width: calc(var(--base-unit) * 5);
        --mcds-iconbutton-height: calc(var(--base-unit) * 5);

        /* Default style for type quaternary */
        --mcds-iconbutton-color: var(--color-icon-primary);
        --mcds-iconbutton-background-color: var(--color-action-passive-subtle);
        --mcds-iconbutton-color-hover: var(--color-action-passive-subtle-hover);
        --mcds-iconbutton-color-active: var(--color-action-passive-subtle-active);
        --mcds-iconbutton-color-focus: var(--color-action-passive-subtle-focus);
        --mcds-iconbutton-box-shadow-color-focus: var(--color-ui-primary);
    }

    .primary {
        --mcds-iconbutton-color: var(--color-icon-inverse);
        --mcds-iconbutton-background-color: var(--color-action-standard);
        --mcds-iconbutton-color-hover: var(--color-action-standard-hover);
        --mcds-iconbutton-color-active: var(--color-action-standard-active);
        --mcds-iconbutton-color-focus: var(--color-action-standard-focus);
        --mcds-iconbutton-box-shadow-color-focus: var(--color-ui-primary);
    }
    .secondary {
        --mcds-iconbutton-color: var(--color-icon-primary);
        --mcds-iconbutton-background-color: var(--color-action-standard-subtle);
        --mcds-iconbutton-border: 1px solid var(--color-container-border-secondary); /* used only variant=secondary */
        --mcds-iconbutton-border-active: var(--mcds-iconbutton-border); /* used only variant=secondary */
        --mcds-iconbutton-color-hover: var(--color-action-standard-subtle-hover);
        --mcds-iconbutton-color-active: var(--color-action-standard-subtle-active);
        --mcds-iconbutton-color-focus: var(--color-action-standard-subtle-focus);
        --mcds-iconbutton-box-shadow-color-focus: var(--color-ui-primary);
    }
    .tertiary {
        --mcds-iconbutton-color: var(--color-icon-primary);
        --mcds-iconbutton-background-color: var(--color-action-passive);
        --mcds-iconbutton-color-hover: var(--color-action-passive-hover);
        --mcds-iconbutton-color-active: var(--color-action-passive-active);
        --mcds-iconbutton-color-focus: var(--color-action-passive-focus);
        --mcds-iconbutton-box-shadow-color-focus: var(--color-ui-primary);
    }
    .destructive {
        --mcds-iconbutton-color: var(--color-icon-inverse);
        --mcds-iconbutton-background-color: var(--color-action-negative);
        --mcds-iconbutton-color-hover: var(--color-action-negative-hover);
        --mcds-iconbutton-color-active: var(--color-action-negative-active);
        --mcds-iconbutton-color-focus: var(--color-action-negative-focus);
        --mcds-iconbutton-box-shadow-color-focus: var(--color-ui-primary);
    }

    .large {
        --mcds-iconbutton-padding: var(--space-component-stack-padding-large) var(--space-component-inline-padding-medium);
        --mcds-iconbutton-width: calc(var(--base-unit) * 6);
        --mcds-iconbutton-height: calc(var(--base-unit) * 6);
    }
    .small {
        --mcds-iconbutton-padding: var(--space-component-stack-padding-small) var(--space-component-inline-padding-x-small);
        --mcds-iconbutton-width: calc(var(--base-unit) * 4);
        --mcds-iconbutton-height: calc(var(--base-unit) * 4);
    }
}

.root {
    transition:
        box-shadow var(--duration-base) ease-in-out 0s,
        background-color var(--duration-base) ease-in-out 0s;
    align-items: center;
    background-color: transparent;
    border-radius: 100%;
    cursor: pointer;
    display: inline-flex;
    height: 32px;
    justify-content: center;
    line-height: normal;
    overflow: hidden;
    user-select: none;
    vertical-align: middle;
    width: 32px;

    &:not([disabled]):hover {
        background-color: var(--bg-interactive-color);
    }


    /*  Override default link styles. */
    a& {
        color: inherit;
    }

    &:focus,
    &:active,
    &[aria-pressed="true"] {
        box-shadow: 0 0 0 2px var(--border-interactive-color);

        /*  High-contrast mode */
        outline: 2px solid transparent;
    }

    &[disabled] {
        opacity: 0.3;
        cursor: not-allowed;
    }

    transition: transform 0.2s ease-in-out 0s;
    &:active {
        transform: translateY(4px);

        &:focus {
            outline: 0;
            outline-offset: 0;
        }

        @media (prefers-reduced-motion) {
            transform: translateY(0);
        }
    }
}

:global(.mcds-redesign-molecules) {
    .root {
        transition:
            box-shadow var(--duration-base) ease-in-out 0s,
            background-color var(--duration-base) ease-in-out 0s;
        align-items: center;
        background-color: var(--mcds-iconbutton-background-color);
        border-radius: var(--mcds-iconbutton-border-radius);
        cursor: pointer;
        display: inline-flex;
        height: auto;
        justify-content: center;
        line-height: normal;
        overflow: hidden;
        user-select: none;
        vertical-align: middle;
        width: auto;

        padding: var(--mcds-iconbutton-padding);
        color: var(--mcds-iconbutton-color);
        border: var(--mcds-iconbutton-border);

        /*  Override default link styles. */
        a& {
            color: var(--mcds-iconbutton-color);
        }

        &:not([disabled]):hover {
            background-color: var(--mcds-iconbutton-color-hover);
        }

        &:focus {
            outline: 1px solid var(--color-icon-inverse);
            box-shadow: 0 0 0 3px var(--mcds-iconbutton-box-shadow-color-focus);
            background-color: var(--mcds-iconbutton-color-focus) !important;
        }

        &:active {
            outline: none;
            box-shadow: none;
            background-color: var(--mcds-iconbutton-color-active) !important; /* Add !important to ensure it takes precedence */
        }

        svg {
            width: var(--mcds-iconbutton-width);
            height: var(--mcds-iconbutton-height);
        }

        &[disabled],
        a&:not([href]) {
            opacity: var(--mcds-iconbutton-opacity);
            cursor: not-allowed;
        }
    }
}
