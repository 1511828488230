.root {
    --cell-size: calc(var(--base-unit) * 10);
    width: auto;
    max-width: calc(var(--cell-size) * 7);
}

.month {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-4);
}

.calendar {
    font: var(--font-text-small);
    border-collapse: separate;
    border-spacing: 0;
    margin: 0 auto;

    th {
        font-weight: var(--font-weight-bold);
    }

    th,
    td {
        width: var(--cell-size);
        height: var(--cell-size);

        button {
            display: block;
            width: var(--cell-size);
            height: var(--cell-size);
            border: none;
            background-color: transparent;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0 0 0 2px transparent;
            transition:
                box-shadow 50ms ease-in-out 0s,
                background-color 50ms ease-in-out 0s;

            &[aria-disabled='true'] {
                color: var(--disabled-color);
                cursor: default;

                &:hover {
                    background-color: transparent;
                }
            }

            &:focus {
                outline: none;
                box-shadow:
                    inset 0 0 0 2px var(--interactive-color),
                    inset 0 0 0 4px var(--bg-lvl-1-color);
            }

            &:hover {
                background-color: var(--bg-interactive-color);
            }

            &:active:not([aria-disabled='true']) {
                box-shadow:
                    0 0 0 2px var(--bg-lvl-1-color),
                    inset 0 0 0 2px var(--interactive-color);
                background-color: var(--interactive-color);
                color: var(--text-secondary-color);
                font-weight: var(--font-weight-bold);
            }

            &[aria-pressed='true']:not([aria-disabled='true']) {
                background-color: var(--interactive-color);
                color: var(--interactive-inverted-color);
                font-weight: var(--font-weight-bold);
            }
        }
    }
}

.isWithinRange {
    background-color: var(--bg-lvl-3-color);
}

:global(.mcds-redesign-molecules) {
    .root {
        width: auto;
        max-width: none;

        --cell-width: calc(var(--base-unit) * 9);
        --cell-height: calc(var(--base-unit) * 8);
    }
    .wrapper {
        max-width: calc(var(--cell-width) * 7);
    }
    .calendar {
        font: var(--font-text-small);
        border-collapse: separate;
        border-spacing: 0;
        margin: 0 auto;

        th {
            font-weight: var(--font-weight-body);
            color: var(--color-text-secondary);
        }

        th,
        td {
            width: var(--cell-width);
            height: var(--cell-height);

            button {
                color: var(--color-text-primary);
                display: block;
                width: var(--cell-width);
                height: var(--cell-height);
                border: none;
                background-color: transparent;
                border-radius: var(--radius-x-small);
                cursor: pointer;
                box-shadow: 0 0 0 2px transparent;
                transition:
                    box-shadow 50ms ease-in-out 0s,
                    background-color 50ms ease-in-out 0s;

                &[aria-disabled='true'] {
                    color: var(--color-text-disabled);
                    cursor: default;

                    &:hover {
                        background-color: transparent;
                    }
                }

                &:focus {
                    outline: none;
                    background-color: var(--color-action-passive-subtle-hover);
                    box-shadow:
                        inset 0 0 0 2px var(--color-ui-primary),
                        inset 0 0 0 3px var(--bg-lvl-1-color);
                }

                &:hover {
                    background-color: var(--color-action-passive-subtle-hover);
                    border-radius: var(--radius-x-small);
                }
                &:active:not([aria-disabled='true']) {
                    background-color: var(--color-action-passive-subtle-active);
                    box-shadow: none;
                    color: var(--color-text-primary);
                    font-weight: var(--font-weight-component);
                }
                &[aria-pressed='true']:not([aria-disabled='true']) {
                    background: var(--color-ui-secondary);
                    color: var(--interactive-inverted-color);
                    font-weight: var(--font-weight-component);
                    box-shadow: none;
                }
                &[aria-pressed='true']:not([aria-disabled='true']):hover {
                    background: var(--color-ui-secondary-hover);
                }
                &[aria-pressed='true']:not([aria-disabled='true']):active {
                    background: var(--color-ui-secondary-active);
                }
                &.currentDate {
                    border: 1px solid var(--color-ui-secondary);
                    color: var(--color-text-primary);
                }
                &.currentDate:focus {
                    border: 1px solid var(--color-ui-secondary);
                    outline: none;
                    background-color: var(--color-action-passive-subtle-hover);
                    box-shadow:
                        inset 0 0 0 2px var(--color-ui-primary),
                        inset 0 0 0 3px var(--bg-lvl-1-color),
                        inset 0 0 0 4px var(--color-ui-secondary);
                }
                &.currentDate:active:not([aria-disabled='true']) {
                    background-color: var(--color-action-passive-subtle-active);
                    box-shadow: none;
                    color: var(--color-text-primary);
                    font-weight: var(--font-weight-component);
                }
                &.currentDate[aria-pressed='true']:not([aria-disabled='true']) {
                    border: none;
                    background: var(--color-ui-secondary);
                    color: var(--interactive-inverted-color);
                    font-weight: var(--font-weight-component);
                    box-shadow: none;
                }
                &.currentDate[aria-pressed='true']:not([aria-disabled='true']):hover {
                    background: var(--color-ui-secondary-hover);
                }
                &.currentDate[aria-pressed='true']:not([aria-disabled='true']):active {
                    background: var(--color-ui-secondary-active);
                }
            }
        }
        .isWithinRange {
            background-color: var(--color-action-standard-subtle-active);
            button {
                &:hover {
                    background-color: var(--color-action-passive-hover);
                    border-radius: var(--radius-none);
                }
                &[aria-pressed='true']:hover {
                    border-radius: var(--radius-x-small);
                }
                &:active {
                    background-color: var(--color-action-passive-active);
                }
                &.currentDate:hover {
                    border-radius: var(--radius-x-small);
                }
            }
        }
    }

    .emptyButtonPlaceholder {
        width: calc(var(--base-unit) * 8);
    }
}
