/*********************************************

    TYPEFACES

    Top-level compiled file intended to represent the custom fonts we are
    bringing in.

    You'll notice that we are not bringing in italicized versions of these
    typefaces. This is an intentional design decision to discourage use of
    italics in Mailchimp.

*********************************************/


@font-face {
    font-family: 'Means Web';
    src: url('https://cdn-images.mailchimp.com/product/fonts/mailchimp/means/Means-Regular-Web.woff2') format('woff2');
    font-weight: 400;
    font-display: fallback;
}

// Deprecated weight of Means
@font-face {
    font-family: 'Means Web';
    src: url('https://cdn-images.mailchimp.com/product/fonts/mailchimp/means/Means-Light-Web.woff2') format('woff2');
    font-weight: 300;
    font-display: fallback;
}

@font-face {
    font-family: 'Graphik Web';
    src: url('https://cdn-images.mailchimp.com/product/fonts/mailchimp/graphik/Graphik-Regular-Web.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik Web';
    src: url('https://cdn-images.mailchimp.com/product/fonts/mailchimp/graphik/Graphik-Medium-Web.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}
