/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

/*  Styles for dialog container */
.container {
    --bottom-sheet-border-radius: calc(var(--base-unit) * 3);
    --wide-min-width: 400;
    --wide-max-width: 1440;
    --wide-min-margin: 8;
    --wide-max-margin: 24;
    --unit: 1px;

    display: flex;
    position: fixed;
    bottom: 0;
    top: 5%;
    width: 100%;
    padding: 0 calc(((clamp(calc(var(--wide-min-width) * var(--unit)), 100%, calc(var(--wide-max-width) * var(--unit))) - calc(var(--wide-min-width) * var(--unit))) * (calc(var(--wide-max-margin) - var(--wide-min-margin))) / (calc(var(--wide-max-width) - var(--wide-min-width)))) + calc(var(--wide-min-margin) * var(--unit)));

    @media (prefers-reduced-motion) {
        transition: none;
    }

    &.expandable {
        &.dragging {
            transition: none;
        }

        &.closed,
        &.expanded,
        &.open {
            transition: top var(--animation-speed-slow) var(--animation-curve);
        }

        &.closed {
            /*  overrides inline style top pixel value */
            top: 100% !important;
        }

        &.open {
            /*  overrides inline style top pixel value */
            top: 65% !important;
        }

        &.expanded {
            /*  overrides inline style top pixel value */
            top: 5% !important;
        }

        &:not(.expanded) .body {
            overflow: hidden;
        }
    }
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--bg-underlay-color);
    z-index: var(--z-modal);
}

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--bg-page-color);
    border-top-left-radius: var(--bottom-sheet-border-radius);
    border-top-right-radius: var(--bottom-sheet-border-radius);


    /*  @media (prefers-reduced-motion) { */

    /*      transition: none; */

    /*  } */
}

.dragIndicator {
    position: absolute;
    top: var(--spacing-2);
    left: 50%;
    height: var(--base-unit);
    width: calc(var(--base-unit) * 8);
    margin-left: calc(var(--base-unit) * -4);
    border-radius: 40px;
    background: var(--border-light-color);
}

.header {
    position: sticky;
    padding: var(--spacing-6);
}


.body {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font: var(--font-text-medium);
    padding: var(--spacing-6);
    padding-top: 0;
}


/*  Apply this global to the content that should recede when the modal is open */
:global(.wink-bottom-sheet-receding-content) {
    @media (prefers-reduced-motion: no-preference) {
        transition: all var(--animation-speed-slow) var(--animation-curve);
    }

    box-shadow: 0 0 0 60px var(--border-dark-color);
    overflow: auto;

    @media (prefers-reduced-motion) {
        transition: none;
    }
}

:global(.wink-bottom-sheet-receding-content-active) {
    transform: scale(0.95);
    border-radius: var(--bottom-sheet-border-radius);
    border-collapse: separate;
    box-shadow: 0 0 0 120px var(--border-dark-color);
}


/*  here be dragons until we can use form inside the Modal body */
.root > form {
    min-height: 0;
}


/*  scroll lock */

.lockScroll {
    height: 100vh;
    overflow: hidden;
}

