.option {
    cursor: pointer;
    padding: var(--spacing-3) var(--spacing-5);
    display: flex;
    align-items: center;
    word-break: break-word;

    &[aria-selected="true"] {
        background-color: var(--bg-lvl-2-color);
        color: var(--text-primary-color);

        .selectedIcon {
            fill: var(--interactive-color);
            margin-left: auto;
            flex-shrink: 0;
        }
    }

    &:not([aria-selected="true"]) .selectedIcon {
        visibility: hidden;
    }

    &[aria-disabled="true"] {
        --text-secondary-color: var(--disabled-color);
        color: var(--disabled-color);
        cursor: not-allowed;

        &:hover {
            background-color: initial;
        }
    }
}

.isHighlighted {
    --text-secondary-color: var(--text-inverted-color);
    background-color: var(--bg-inverted-color);
    color: var(--text-inverted-color);

    &[role="option"] > :not(span) {
        color: var(--interactive-inverted-color) !important;
    }

    &[aria-selected="true"] {
        background-color: var(--bg-inverted-color);
        color: var(--text-inverted-color);

        .selectedIcon {
            fill: var(--interactive-inverted-color);
        }
    }
}

:global(.mcds-redesign-molecules) {
    .option {
        padding: var(--space-component-inline-padding-medium);
        gap: var(--space-component-inline-padding-medium);
        align-self: stretch;
        color: var(--color-text-primary);
        font-family: var(--font-family-component);
        font-size: var(--font-size-component-small);
        font-style: normal;
        font-weight: var(--font-weight-component);
        line-height: var(--line-height-component);

        &[aria-selected="true"] {
            background-color: unset;
            color: var(--color-text-primary);

            .selectedIcon {
                margin-left: 0;
                fill: var(--color-text-primary);
            }
        }

        &:hover {
            border-radius: var(--radius-none);
            background: var(--color-action-standard-subtle-hover);
        }

        &:active {
            border-radius: var(--radius-none);
            background: var(--color-action-standard-subtle-active);
        }


        &:focus {
            border-radius: var(--radius-none);
            background: var(--color-action-standard-subtle-focus);
        }


        &[aria-disabled="true"] {
            color: var(--color-text-disabled) !important;
            cursor: not-allowed;
            .selectedIcon {
                margin-left: 0;
                color: var(--color-text-disabled) !important;
            }
        }
        & > * {
            justify-content: center;
        }
    }

    .isHighlighted {
        background: var(--color-action-standard-subtle-focus);
        color: var(--color-text-primary);
        --text-secondary-color: var(--color-text-secondary);

        &[role="option"] > :not(span) {
            color: var(--color-text-primary) !important;
        }

        &[aria-selected="true"] {
            background: var(--color-action-standard-subtle-focus);
            color: var(--color-text-primary);
        }

        &:hover {
            background: var(--color-action-standard-subtle-hover);
        }
    }
}
