/*************************
*  Toggletip body
*************************/

.toggletip {
    pointer-events: none;
    z-index: var(--z-modal);
    padding: var(--spacing-3);
    font: var(--font-text-small);
    background: var(--bg-inverted-page-color);
    color: var(--text-inverted-color);
    box-shadow: var(--elevation-lvl-1);
    border-radius: var(--border-overlay-radius);
    max-width: calc(var(--readable-max-width) / 2);

    transition: transform 0.2s ease-in-out;
    @media (prefers-reduced-motion) {
        transition: none;
    }
}

/*************************
* Arrow
*************************/

:root {
    /*  Make sure the arrow size is odd. This will help pixel precision. */
    --arrow-size: calc(var(--spacing-2) - 1);
}

.arrow {
    pointer-events: none;
    z-index: -1;

    /*  Intentional: We want this to be double the border. */
    width: calc(var(--arrow-size) * 2);
    height: calc(var(--arrow-size) * 2);

    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border: var(--arrow-size) solid transparent;
    }


    /*  Arrow directions */

    [data-popup-arrow-placement^='top'] > & {
        top: 100%;
        &::before {
            border-top-color: var(--border-dark-color);
        }
    }

    [data-popup-arrow-placement^='bottom'] > & {
        bottom: 100%;
        &::before {
            border-bottom-color: var(--border-dark-color);
        }
    }

    [data-popup-arrow-placement^='right'] > & {
        right: 100%;
        &::before {
            border-right-color: var(--border-dark-color);
        }
    }

    [data-popup-arrow-placement^='left'] > & {
        left: 100%;
        &::before {
            border-left-color: var(--border-dark-color);
        }
    }
}
