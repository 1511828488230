.stepper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    background: var(--color-input-background-primary);
    height: var(--mcds-input-height);
    border-radius: 0 calc(var(--base-unit) * 1.5) calc(var(--base-unit) * 1.5) 0;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;

    &.large {
        margin-right: calc(var(--base-unit) * -2);
        margin-top: calc(var(--base-unit) * -2);
        margin-bottom: calc(var(--base-unit) * -2);

        .stepperUp {
            padding: 1px calc(var(--base-unit) * 2) 2px 7px;
            height: calc(var(--base-unit) * 5);
        }
        .stepperDown {
            padding: 2px calc(var(--base-unit) * 2) 2px 7px;
            height: calc(var(--base-unit) * 5);
        }
    }
    &.medium {
        margin-right: calc(var(--base-unit) * -1.5);
        margin-top: calc(var(--base-unit) * -1);
        margin-bottom: calc(var(--base-unit) * -1);

        .stepperUp {
            padding: 2px calc(var(--base-unit) * 1.5) 1.5px 5px;
            height: calc(var(--base-unit) * 4);
        }
        .stepperDown {
            padding: 2px calc(var(--base-unit) * 1.5) 1.5px 5px;
            height: calc(var(--base-unit) * 4);
        }
    }
}

.stepperUp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-left: 1px solid var(--color-input-border-primary);
    border-bottom: 1px solid var(--color-input-border-primary);
    background: var(--color-action-standard-subtle);
    border-radius: 0 calc(var(--base-unit) * 1.5) 0 0;
    box-sizing: border-box;
    &:hover {
        background-color: var(--color-action-standard-subtle-hover);
    }
    &:focus-visible {
        background: var(--color-action-standard-subtle-active);
        outline: 2px solid var(--color-ui-primary);
        outline-offset: -2px;
        z-index: 1;
    }
    &:active {
        background-color: var(--color-action-standard-subtle-active);
        box-shadow: 0;
    }

}

.stepperDown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-left: 1px solid var(--color-input-border-primary);
    background: var(--color-action-standard-subtle);
    border-radius: 0 0 calc(var(--base-unit) * 1.5) 0;
    box-sizing: border-box;
    &:hover {
        background-color: var(--color-action-standard-subtle-hover);
    }
    &:focus-visible {
        background: var(--color-action-standard-subtle-active);
        outline: 2px solid var(--color-ui-primary);
        outline-offset: -2px;
        z-index: 1;
        margin-top: -1px;
    }
    &:active {
        background-color: var(--color-action-standard-subtle-active);
        box-shadow: 0;
    }
}

.disabled {
    background: var(--action-standard-subtle-disabled);
    pointer-events: none;
    cursor: not-allowed;
}
