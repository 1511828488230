/* File allow-listed for globally scoped CSS. Reach out to #front-end-help with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

// `WinkStyles` is meant for interop between the design system's and monolith's global
// stylesheet. This file applies design system styles to `WinkStyles` and its children.
//
// This is NOT a global stylesheet. Rather, this WORKS WITH the monolith's
// global stylesheet, specifically `global-w-front-door.less`. So, in this file,
// we make certain assumptions about global styles that you wouldn't find in
// Wink's true global stylesheet, `@mc/wink/styles/global.less`.

@import '@mc/wink/styles/_theme.less';

:global(.wink) {
    @import '@mc/wink/styles/_wink-icons.less';
    @import '@mc/wink/styles/_typefaces.less';
    @import '@mc/wink/styles/_reset.less';
    @import '@mc/wink/styles/_typography.less';

    // Copy Wink's relevant global body styles.
    color: var(--text-primary-color);
    font: var(--font-text-medium);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    vertical-align: baseline;
}

@import '@mc/wink/styles/_utilities.less';
@import '@mc/wink/styles/_ids-overrides.less';

