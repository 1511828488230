.root {
    max-width: var(--readable-max-width);

    fieldset > & {
        font: var(--font-text-small);
    }

    textarea {
        font: inherit;
        font-size: var(--input-font-size, var(--font-size-medium));
        line-height: var(--line-height-medium);
        width: 100%;
        display: block;
        padding-top: var(--input-padding-top, 10px);
        padding-bottom: var(--input-padding-bottom, 10px);
        padding-left: var(--input-padding-left, var(--spacing-4));
        padding-right: var(--input-padding-right, var(--spacing-4));
        border: 1px solid var(--border-input-color);
        color: var(--text-primary-color);
        background-color: var(--bg-lvl-1-color);
        border-radius: var(--border-input-radius);
        box-sizing: border-box;
        transition: box-shadow 200ms ease-in-out, border-color 200ms ease-in-out, background-color 150ms var(--animation-curve);

        /*  Mobile Safari workaround */
        background-clip: padding-box;

        &:not([disabled]):focus {
            outline: 2px solid var(--border-interactive-color);
            outline-offset: 1px;
            border: 1px solid var(--border-input-color-hover);


            /*  Mobile Safari workaround */
            box-shadow: inset 0 -2px 0 transparent;
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: transparent;
            color: var(--disabled-color);
            border-color: var(--disabled-border-color);
        }

        &[readonly] {
            background-color: transparent;
        }

        &::placeholder {
            color: var(--text-secondary-color);
        }

        &::-ms-clear {
            display: none;
        }
        min-height: 5.5em;

        &:not([disabled]):not([readonly]):hover {
            border-color: var(--border-dark-color);
        }
    }
}

.before {
    display: flex;
    justify-content: space-between;


    /*  Add bottom margin to `.before`'s direct children. If elements before the */

    /*  input are visually empty, no margin should display before the input. */
    > * {
        margin-bottom: var(--spacing-2);
    }
}


/*  Add top margin to elements after the input. If no elements exist after the */

/*  input, no margin should display after the input. */
.after {
    margin-top: var(--spacing-2);
}

.secondary {
    color: var(--text-secondary-color);
}

.error textarea {
    border-color: var(--border-error-color);

    &:focus {
        outline-color: var(--border-error-color);
    }
}

.errorMessage {
    color: var(--text-error-color);

    a {
        color: inherit;
    }
}

:global(.mcds-redesign-molecules) {
    .root {
        display: flex;
        flex-direction: column;
        gap: var(--space-component-gap-small);
        min-width: calc(var(--base-unit) * 40);

        textarea {
            background: var(--color-input-background-primary);
            border-radius: var(--radius-small);
            border: 1px solid var(--color-input-border-primary);
            color: var(--color-text-primary);
            font-family: var(--font-family-component);
            font-weight: 400;
            transition: box-shadow 200ms ease-in-out, border-color 200ms ease-in-out, background-color 150ms var(--animation-curve);

            &:not([disabled]):focus {
                background: var(--color-input-background-primary-focus);
                border-color: var(--color-input-border-primary-focus);
                box-shadow: none;
                outline-offset: -2px;
                outline: 2px solid var(--color-input-border-primary-focus);
            }

            &[disabled] {
                background: var(--color-input-background-disabled);
                border-color: var(--color-input-border-disabled);
                color: var(--color-text-disabled);
            }

            &[readonly] {
                background: var(--color-input-background-readonly);
                border-color: var(--color-input-border-readonly);
            }

            &::placeholder {
                color: var(--color-text-quaternary);
            }

            &:not([disabled]):not([readonly]):hover {
                background: var(--color-input-background-primary-hover);
                border-color: var(--color-input-border-primary-hover);
            }
        }
    }

    .before {
        display: flex;
        align-items: center;
        gap: var(--space-component-gap-x-small);

        .required {
            color: var(--color-ui-negative);
            font-size: var(--font-size-component-x-small);
            font-weight: 500;
            line-height: var(--line-height-component);
        }

        :global(.mcds-label-default) {
            flex-grow: 1;
            color: var(--color-text-secondary);
            font-weight: var(--font-weight-body);
            line-height: var(--line-height-component);
        }

        > * {
            margin-bottom: 0;
        }
    }

    .after {
        margin-top: 0;
        display: flex;
        align-items: center;
        gap: var(--space-component-gap-small);

        .message {
            color: var(--color-text-secondary);
            font-size: var(--font-size-component-x-small);
            line-height: var(--line-height-component);

            a {
                color: inherit;
            }
        }

        svg {
            color: var(--color-text-inverse);
            height: var(--space-medium);
            width: var(--space-medium);
        }
    }

    .secondary {
        color: var(--color-text-tertiary);
        line-height: var(--line-height-component);
    }

    .error {
        textarea {
            border-color: var(--color-input-border-error);
            background-color: var(--color-input-background-error);

            &:not([disabled]):not([readonly]):hover {
                background-color: var(--color-input-background-error);
                border-color: var(--color-input-border-error);
            }

            &:not([disabled]):focus,
            &:not([disabled]):focus:hover {
                background: var(--color-input-background-error-focus);
                outline-color: var(--color-input-border-error);
            }
        }

        .after {
            svg path {
                fill: var(--color-ui-negative);
            }
            .message {
                color: var(--color-ui-negative);
            }
        }
    }

    .warning {
        textarea {
            border-color: var(--color-input-border-warning);
            background-color: var(--color-input-background-warning);

            &:not([disabled]):not([readonly]):hover {
                background-color: var(--color-input-background-warning);
                border-color: var(--color-input-border-warning);
            }

            &:not([disabled]):focus,
            &:not([disabled]):focus:hover {
                background: var(--color-input-background-warning-focus);
                outline-color: var(--color-input-border-warning);
            }
        }

        .after {
            svg circle {
                fill: var(--color-ui-attention);
            }
        }
    }

    .large {
        .before {
            :global(.mcds-label-default) {
                font-size: var(--font-size-component-small);
                line-height: var(--line-height-component);
            }
        }

        textarea {
            font-size: var(--font-size-component-small);
            line-height: var(--line-height-component);
            min-height: calc(var(--base-unit) * 19);
            padding: var(--space-component-stack-padding-x-large) var(--space-component-inline-padding-large);
        }

        .secondary {
            font-size: var(--font-size-component-small);
        }
    }

    .medium {
        .before {
            :global(.mcds-label-default) {
                font-size: var(--font-size-component-x-small);
                line-height: var(--line-height-component);
            }
        }

        textarea {
            font-size: var(--font-size-component-x-small);
            line-height: var(--line-height-component);
            min-height: calc(var(--base-unit) * 16);
            padding: var(--space-component-stack-padding-large) var(--space-component-inline-padding-medium);
        }

        .secondary {
            font-size: var(--font-size-component-x-small);
        }
    }

    .disabled {
        .before {
            :global(.mcds-label-default) {
                color: var(--color-text-disabled);
            }
        }

        .secondary {
            color: var(--color-text-disabled);
        }

        .after .message {
            color: var(--color-text-disabled);
        }
    }

    .errorMessage {
        color: var(--color-ui-negative);

        a {
            color: inherit;
        }
    }
}
