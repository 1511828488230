.header {
    border-bottom: 1px solid var(--border-light-color);
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    padding-top: var(--spacing-4);
    padding-bottom: var(--spacing-4);
}

.actions {
    flex: 0 0 auto;
}

.header,
.description {
    margin-bottom: var(--spacing-4);
}
