/* File allow-listed for globally scoped CSS. Reach out to #xp-team with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

:root,
[data-theme="storybook-mailchimp"] {
    /******
    * IDS SEMANTIC DESIGN TOKENS

    * IDS externalized tokens only export semantic tokens. This project will default to using those tokens
    * instead of primitive so that migration to IDS Token package is seamless. These new values are a part of MCDS Redesign.
    * This can be removed once the package is imported.
    ********/

    --agave-80-10: rgba(0, 130, 141, 0.1); /* Agave-80 at 10%. Not an IDS token. Temporary til Buttons are redesigned. */

    /* Pulled directly from https://uxfabric.intuitcdn.net/components/design-systems/tokens/ddms3.0/prod/24.0.0/css/mailchimp.css
    TO-DO: Once theme editor version is final, we will swap the import and remove manual tokens below. */
    --color-action-complementary: #5d686f;
    --color-action-complementary-active: #3c4348;
    --color-action-complementary-border: rgba(240, 244, 246, 0);
    --color-action-complementary-focus: #4c555b;
    --color-action-complementary-hover: #4c555b;
    --color-action-complementary-subtle: rgba(43, 49, 53, 0);
    --color-action-complementary-subtle-active: #3c4348;
    --color-action-complementary-subtle-focus: #2b3135;
    --color-action-complementary-subtle-hover: #2b3135;
    --color-action-negative: #db334d;
    --color-action-negative-active: #8f1229;
    --color-action-negative-focus: #b61a37;
    --color-action-negative-hover: #b61a37;
    --color-action-negative-subtle: #e2e9ed;
    --color-action-negative-subtle-active: #c3ced5;
    --color-action-negative-subtle-focus: #d5dee3;
    --color-action-negative-subtle-hover: #d5dee3;
    --color-action-passive: #e2e9ed;
    --color-action-passive-active: #c3ced5;
    --color-action-passive-border: #d5dee3;
    --color-action-passive-focus: #d5dee3;
    --color-action-passive-hover: #d5dee3;
    --color-action-passive-subtle: rgba(248, 250, 251, 0);
    --color-action-passive-subtle-active: #e2e9ed;
    --color-action-passive-subtle-focus: #f0f4f6;
    --color-action-passive-subtle-hover: #f0f4f6;
    --color-action-special-use: #ffe01b;
    --color-action-special-use-active: #e3c81b;
    --color-action-special-use-focus: #eace1b;
    --color-action-special-use-hover: #eace1b;
    --color-action-special-use-subtle: #017e89;
    --color-action-special-use-subtle-active: #005056;
    --color-action-special-use-subtle-focus: #006771;
    --color-action-special-use-subtle-hover: #006771;
    --color-action-standard: #017e89;
    --color-action-standard-active: #005056;
    --color-action-standard-focus: #006771;
    --color-action-standard-hover: #006771;
    --color-action-standard-subtle: #ffffff;
    --color-action-standard-subtle-active: #e2e9ed;
    --color-action-standard-subtle-focus: #f0f4f6;
    --color-action-standard-subtle-hover: #f0f4f6;
    --color-code-attribute: #00892e;
    --color-code-boolean: #6100c2;
    --color-code-comment: #9aa7b0;
    --color-code-keyword: #d72792;
    --color-code-line-highlight: #f0f4f6;
    --color-code-line-number: #859299;
    --color-code-number: #c84f00;
    --color-code-operator: #859299;
    --color-code-qualifier: #3bd85e;
    --color-code-string: #00828d;
    --color-code-text: #21262a;
    --color-code-type: #fca354;
    --color-code-variable-1: #21262a;
    --color-code-variable-2: #21262a;
    --color-code-variable-3: #fca354;
    --color-container-background-accent: #e0edff;
    --color-container-background-accent-active: #a0cfff;
    --color-container-background-accent-focus: #c5e1ff;
    --color-container-background-accent-hover: #c5e1ff;
    --color-container-background-accent-selected: #a0cfff;
    --color-container-background-attention: #ffeac7;
    --color-container-background-beta: #c2f5ff;
    --color-container-background-complementary: #21262a;
    --color-container-background-info: #f0e9ff;
    --color-container-background-inverse: #21262a;
    --color-container-background-negative: #ffe7e7;
    --color-container-background-neutral: #e2e9ed;
    --color-container-background-new: #ffe6f4;
    --color-container-background-positive: #d2f8d5;
    --color-container-background-primary: #ffffff;
    --color-container-background-quaternary: #e2e9ed;
    --color-container-background-secondary: #f8fafb;
    --color-container-background-special-use-1: #7cbcff;
    --color-container-background-special-use-2: #00d5b0;
    --color-container-background-special-use-3: #ff808c;
    --color-container-background-special-use-4: #d5dee3;
    --color-container-background-tertiary: #f0f4f6;
    --color-container-border-accent: #017e89;
    --color-container-border-attention: #c84f00;
    --color-container-border-complementary: #5d686f;
    --color-container-border-info: #a275ff;
    --color-container-border-negative: #db334d;
    --color-container-border-positive: #00a63b;
    --color-container-border-primary: #e2e9ed;
    --color-container-border-secondary: #d5dee3;
    --color-container-border-tertiary: #c3ced5;
    --color-container-overlay: rgba(0, 0, 0, 0.5);
    --color-data-attention: #ff8000;
    --color-data-attention-100: #ff6a00;
    --color-data-attention-200: #ff8000;
    --color-data-attention-300: #ff9331;
    --color-data-attention-400: #ffac61;
    --color-data-category-1: #205ea3;
    --color-data-category-1-background: #7cbcff;
    --color-data-category-2: #009eac;
    --color-data-category-2-background: #38e8f6;
    --color-data-category-3: #e56c1d;
    --color-data-category-3-background: #c0abff;
    --color-data-category-4: #f7576c;
    --color-data-category-4-background: #fca354;
    --color-data-category-5: #a275ff;
    --color-data-category-5-background: #ff9ba4;
    --color-data-category-6: #8f1229;
    --color-data-category-6-background: #ff95d3;
    --color-data-category-7: #006771;
    --color-data-category-7-background: #d0c2ff;
    --color-data-category-8: #8d4d00;
    --color-data-category-8-background: #5bef76;
    --color-data-category-9: #28005a;
    --color-data-category-9-background: #a0cfff;
    --color-data-category-10: #390e00;
    --color-data-category-10-background: #f9c741;
    --color-data-negative: #db334d;
    --color-data-negative-100: #f7576c;
    --color-data-negative-200: #db334d;
    --color-data-negative-300: #b61a37;
    --color-data-negative-400: #8f1229;
    --color-data-neutral: #859299;
    --color-data-neutral-100: #adbac2;
    --color-data-neutral-200: #859299;
    --color-data-neutral-300: #727e85;
    --color-data-neutral-400: #4c555b;
    --color-data-positive: #00a63b;
    --color-data-positive-100: #00bb42;
    --color-data-positive-200: #00a63b;
    --color-data-positive-300: #00892e;
    --color-data-positive-400: #006c23;
    --color-data-primary: #3492ef;
    --color-data-primary-100: #2b77cc;
    --color-data-primary-200: #3492ef;
    --color-data-primary-300: #5dabff;
    --color-data-primary-400: #7cbcff;
    --color-data-secondary: #f54bac;
    --color-data-secondary-100: #ff77c7;
    --color-data-secondary-200: #f54bac;
    --color-data-secondary-300: #d72792;
    --color-data-secondary-400: #b30078;
    --color-data-tertiary: #7c00f6;
    --color-data-tertiary-100: #a275ff;
    --color-data-tertiary-200: #8f4bff;
    --color-data-tertiary-300: #7c00f6;
    --color-data-tertiary-400: #6100c2;
    --color-divider-inverse: #ffffff;
    --color-divider-primary: #859299;
    --color-divider-secondary: #adbac2;
    --color-divider-tertiary: #e2e9ed;
    --color-focus-indicator: #017e89;
    --color-icon-accent: #017e89;
    --color-icon-complementary: #ffffff;
    --color-icon-disabled: #9aa7b0;
    --color-icon-inverse: #ffffff;
    --color-icon-primary: #21262a;
    --color-icon-secondary: #5d686f;
    --color-input-background-disabled: #d5dee3;
    --color-input-background-error: #ffe7e7;
    --color-input-background-error-focus: #fff6f6;
    --color-input-background-primary: #ffffff;
    --color-input-background-primary-active: #ffffff;
    --color-input-background-primary-focus: #ffffff;
    --color-input-background-primary-hover: #f8fafb;
    --color-input-background-primary-selected: #017e89;
    --color-input-background-readonly: #f0f4f6;
    --color-input-background-secondary: #9aa7b0;
    --color-input-background-secondary-active: #727e85;
    --color-input-background-secondary-focus: #859299;
    --color-input-background-secondary-hover: #859299;
    --color-input-background-tertiary: #ffffff;
    --color-input-background-tertiary-active: #f0f4f6;
    --color-input-background-tertiary-focus: #ffffff;
    --color-input-background-tertiary-hover: #f8fafb;
    --color-input-background-warning: #ffeac7;
    --color-input-background-warning-focus: #fff7ec;
    --color-input-border-disabled: #c3ced5;
    --color-input-border-error: #db334d;
    --color-input-border-primary: #c3ced5;
    --color-input-border-primary-active: #017e89;
    --color-input-border-primary-focus: #017e89;
    --color-input-border-primary-hover: #3c4348;
    --color-input-border-primary-selected: #017e89;
    --color-input-border-readonly: #859299;
    --color-input-border-secondary: #5d686f;
    --color-input-border-secondary-active: #017e89;
    --color-input-border-secondary-focus: #017e89;
    --color-input-border-secondary-hover: #2b3135;
    --color-input-border-tertiary: #859299;
    --color-input-border-tertiary-active: #017e89;
    --color-input-border-tertiary-focus: #859299;
    --color-input-border-tertiary-hover: #3c4348;
    --color-input-border-warning: #c84f00;
    --color-input-label: #5d686f;
    --color-input-placeholder: #adbac2;
    --color-input-placeholder-disabled: #d5dee3;
    --color-link-background: #e1f9ff;
    --color-link-background-visited: #e1f9ff;
    --color-link-text: #017e89;
    --color-link-text-active: #005056;
    --color-link-text-complementary: #00b3c2;
    --color-link-text-complementary-visited: #00b3c2;
    --color-link-text-focus: #006771;
    --color-link-text-hover: #006771;
    --color-link-text-visited: #017e89;
    --color-message-background-received: #f0f4f6;
    --color-message-background-sent: #017e89;
    --color-message-text-received: #21262a;
    --color-message-text-sent: #ffffff;
    --color-overlay: rgba(0, 0, 0, 0.5);
    --color-page-background-accent: #e2e9ed;
    --color-page-background-primary: #ffffff;
    --color-page-background-secondary: #f8fafb;
    --color-page-background-tertiary: #f0f4f6;
    --color-progress-indicator: #017e89;
    --color-selection-indicator: #017e89;
    --color-shadow: rgba(76, 85, 91, 0.2);
    --color-text-accent: #017e89;
    --color-text-complementary: #ffffff;
    --color-text-disabled: #859299;
    --color-text-highlight: #c5e1ff;
    --color-text-inverse: #ffffff;
    --color-text-primary: #21262a;
    --color-text-quaternary: #9aa7b0;
    --color-text-secondary: #4c555b;
    --color-text-tertiary: #727e85;
    --color-track-primary: #e2e9ed;
    --color-track-secondary: #c3ced5;
    --color-ui-attention: #c84f00;
    --color-ui-attention-active: #832900;
    --color-ui-attention-hover: #a53900;
    --color-ui-beta: #00b3c2;
    --color-ui-completed: #017e89;
    --color-ui-discover: #2b77cc;
    --color-ui-info: #7c00f6;
    --color-ui-negative: #db334d;
    --color-ui-negative-active: #8f1229;
    --color-ui-negative-hover: #b61a37;
    --color-ui-neutral: #5d686f;
    --color-ui-neutral-active: #3c4348;
    --color-ui-neutral-focus: #4c555b;
    --color-ui-neutral-hover: #4c555b;
    --color-ui-neutral-selected: #3c4348;
    --color-ui-new: #d72792;
    --color-ui-positive: #00892e;
    --color-ui-primary: #017e89;
    --color-ui-primary-active: #005056;
    --color-ui-primary-focus: #006771;
    --color-ui-primary-hover: #006771;
    --color-ui-secondary: #2b3135;
    --color-ui-secondary-active: #181c1f;
    --color-ui-secondary-focus: #21262a;
    --color-ui-secondary-hover: #21262a;
    --color-ui-tertiary: #f0f4f6;
    --color-ui-tertiary-active: #d5dee3;
    --color-ui-tertiary-focus: #e2e9ed;
    --color-ui-tertiary-hover: #e2e9ed;

    --font-size-action-large: 20px;
    --font-size-action-medium: 16px;
    --font-size-action-small: 14px;
    --font-size-action-x-small: 12px;
    --font-size-body-1: 20px;
    --font-size-body-2: 16px;
    --font-size-body-3: 14px;
    --font-size-body-4: 12px;
    --font-size-component-large: 20px;
    --font-size-component-medium: 16px;
    --font-size-component-small: 14px;
    --font-size-component-x-large: 24px;
    --font-size-component-x-small: 12px;
    --font-size-display-1: 84px;
    --font-size-display-2: 72px;
    --font-size-display-3: 60px;
    --font-size-display-4: 48px;
    --font-size-heading-1: 48px;
    --font-size-heading-2: 40px;
    --font-size-heading-3: 34px;
    --font-size-heading-4: 28px;
    --font-size-heading-5: 24px;
    --font-size-heading-6: 20px;
    --font-size-input-label: 14px;
    --font-size-input-text: 14px;
    --duration-appear-emphasize-fast: 500ms;
    --duration-appear-emphasize-moderate: 700ms;
    --duration-appear-emphasize-slow: 800ms;
    --duration-appear-fast: 150ms;
    --duration-appear-moderate: 250ms;
    --duration-appear-slow: 350ms;
    --duration-disappear-emphasize-fast: 500ms;
    --duration-disappear-emphasize-moderate: 700ms;
    --duration-disappear-emphasize-slow: 800ms;
    --duration-disappear-fast: 200ms;
    --duration-disappear-moderate: 250ms;
    --duration-disappear-slow: 300ms;
    --duration-fade-fast: 150ms;
    --duration-fade-moderate: 200ms;
    --duration-fade-slow: 250ms;
    --duration-progress-fast: 500ms;
    --duration-progress-moderate: 1000ms;
    --duration-progress-slow: 1500ms;
    --duration-progress-slowest: 2000ms;
    --duration-transform-emphasize-fast: 500ms;
    --duration-transform-emphasize-moderate: 700ms;
    --duration-transform-emphasize-slow: 800ms;
    --duration-transform-fast: 350ms;
    --duration-transform-moderate: 400ms;
    --duration-transform-slow: 450ms;
    --ease-appear: cubic-bezier(0, 0, 0.25, 1);
    --ease-appear-emphasize: cubic-bezier(0.15, 1.4, 0.25, 1);
    --ease-disappear: cubic-bezier(0.35, 0, 1, 1);
    --ease-disappear-emphasize: cubic-bezier(0.4, 0, 0.6, -0.4);
    --ease-fade: cubic-bezier(0.35, 0, 0.5, 1);
    --ease-transform: cubic-bezier(0.35, 0, 0.25, 1);
    --ease-transform-emphasize: cubic-bezier(0.35, 1.4, 0.25, 1);
    --elevation-level-0: 0 0 0 0;
    --elevation-level-1: 0 1px 4px 0;
    --elevation-level-2: 0 2px 8px 0;
    --elevation-level-3: 0 4px 16px 0;
    --elevation-level-4: 0 6px 24px 0;
    --font-family-body: Graphik Web, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
    --font-family-component: Graphik Web, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
    --font-family-display: Means Web, Georgia, Times, Times New Roman, serif;
    --font-family-heading: Graphik Web, Helvetica Neue, Helvetica, Arial, Verdana, sans-serif;
    --font-name-body: Graphik;
    --font-name-component: Graphik;
    --font-name-display: Means;
    --font-name-heading: Graphik;
    --font-weight-body: 400;
    --font-weight-body-bold: 500;
    --font-weight-body-semibold: 500;
    --font-weight-component: 400;
    --font-weight-component-bold: 500;
    --font-weight-component-semibold: 500;
    --font-weight-display: 400;
    --font-weight-display-bold: 400;
    --font-weight-heading: 500;
    --font-weight-heading-bold: 500;
    --font-weight-input-label: 400;
    --font-weight-input-text: 400;
    --line-height-body: 1.4;
    --line-height-component: 1.2;
    --line-height-display: 1.2;
    --line-height-heading: 1.2;
    --opacity-disabled: 0.4;
    --opacity-shadow: 0.2;
    --radius-action: 6px;
    --radius-full: 9999px;
    --radius-large: 12px;
    --radius-medium: 8px;
    --radius-none: 0;
    --radius-small: 6px;
    --radius-x-large: 16px;
    --radius-x-small: 4px;
    --space-column-gap-large: 24px;
    --space-column-gap-medium: 20px;
    --space-column-gap-small: 16px;
    --space-column-gap-x-large: 40px;
    --space-column-gap-x-small: 8px;
    --space-column-gap-xx-small: 4px;
    --space-component-gap-large: 12px;
    --space-component-gap-medium: 8px;
    --space-component-gap-small: 4px;
    --space-component-gap-x-small: 2px;
    --space-component-inline-padding-large: 10px;
    --space-component-inline-padding-medium: 8px;
    --space-component-inline-padding-small: 6px;
    --space-component-inline-padding-x-large: 12px;
    --space-component-inline-padding-x-small: 4px;
    --space-component-inline-padding-xx-large: 16px;
    --space-component-inline-padding-xx-small: 2px;
    --space-component-inline-padding-xxx-large: 20px;
    --space-component-inline-padding-xxx-small: 0;
    --space-component-stack-padding-large: 8px;
    --space-component-stack-padding-medium: 6px;
    --space-component-stack-padding-small: 4px;
    --space-component-stack-padding-x-large: 10px;
    --space-component-stack-padding-x-small: 2px;
    --space-component-stack-padding-xx-small: 0;
    --space-container-padding-large: 24px;
    --space-container-padding-medium: 20px;
    --space-container-padding-small: 16px;
    --space-container-padding-x-large: 32px;
    --space-container-padding-x-small: 12px;
    --space-container-padding-xx-large: 40px;
    --space-container-padding-xx-small: 8px;
    --space-container-padding-xxx-large: 60px;
    --space-container-padding-xxx-small: 4px;
    --space-large: 24px;
    --space-medium: 16px;
    --space-page-inline: 20px;
    --space-row-gap-large: 24px;
    --space-row-gap-medium: 20px;
    --space-row-gap-small: 16px;
    --space-row-gap-x-large: 40px;
    --space-row-gap-x-small: 8px;
    --space-small: 12px;
    --space-x-large: 40px;
    --space-x-small: 8px;
}

/*********************************************
    DARK MODE
    CSS variables for dark mode.
    One global classname is used to apply dark mode to Storybook,
    the other replicates usage in IDS tokens package.
    Will consolidate once IDS tokens package is imported.
*********************************************/
:global(.dark-mode),
[data-colorscheme="dark"] {
    --color-action-complementary: #2b3135;
    --color-action-complementary-active: #181c1f;
    --color-action-complementary-border: rgba(248, 250, 251, 0);
    --color-action-complementary-focus: #21262a;
    --color-action-complementary-hover: #21262a;
    --color-action-complementary-subtle: rgba(60, 67, 72, 0);
    --color-action-complementary-subtle-active: #2b3135;
    --color-action-complementary-subtle-focus: #3c4348;
    --color-action-complementary-subtle-hover: #3c4348;
    --color-action-negative: #f7576c;
    --color-action-negative-active: #ff9ba4;
    --color-action-negative-focus: #ff808c;
    --color-action-negative-hover: #ff808c;
    --color-action-negative-subtle: #21262a;
    --color-action-negative-subtle-active: #3c4348;
    --color-action-negative-subtle-focus: #2b3135;
    --color-action-negative-subtle-hover: #2b3135;
    --color-action-passive: #3c4348;
    --color-action-passive-active: #5d686f;
    --color-action-passive-border: #3c4348;
    --color-action-passive-focus: #4c555b;
    --color-action-passive-hover: #4c555b;
    --color-action-passive-subtle: rgba(60, 67, 72, 0);
    --color-action-passive-subtle-active: #4c555b;
    --color-action-passive-subtle-focus: #3c4348;
    --color-action-passive-subtle-hover: #3c4348;
    --color-action-special-use: #ffe01b;
    --color-action-special-use-active: #ffe334;
    --color-action-special-use-focus: #ffe22b;
    --color-action-special-use-hover: #ffe22b;
    --color-action-special-use-subtle: #00b3c2;
    --color-action-special-use-subtle-active: #38e8f6;
    --color-action-special-use-subtle-focus: #00d0e0;
    --color-action-special-use-subtle-hover: #00d0e0;
    --color-action-standard: #00b3c2;
    --color-action-standard-active: #38e8f6;
    --color-action-standard-focus: #00d0e0;
    --color-action-standard-hover: #00d0e0;
    --color-action-standard-subtle: #21262a;
    --color-action-standard-subtle-active: #3c4348;
    --color-action-standard-subtle-focus: #2b3135;
    --color-action-standard-subtle-hover: #2b3135;
    --color-code-attribute: #3bd85e;
    --color-code-boolean: #c0abff;
    --color-code-comment: #adbac2;
    --color-code-keyword: #ff95d3;
    --color-code-line-highlight: #3c4348;
    --color-code-line-number: #859299;
    --color-code-number: #fca354;
    --color-code-operator: #859299;
    --color-code-qualifier: #3bd85e;
    --color-code-string: #38e8f6;
    --color-code-text: #e2e9ed;
    --color-code-type: #e56c1d;
    --color-code-variable-1: #e2e9ed;
    --color-code-variable-2: #e2e9ed;
    --color-code-variable-3: #e56c1d;
    --color-container-background-accent: #103661;
    --color-container-background-accent-active: #205ea3;
    --color-container-background-accent-focus: #17497f;
    --color-container-background-accent-hover: #17497f;
    --color-container-background-accent-selected: #205ea3;
    --color-container-background-attention: #651e00;
    --color-container-background-beta: #005056;
    --color-container-background-complementary: #4c555b;
    --color-container-background-info: #490097;
    --color-container-background-inverse: #ffffff;
    --color-container-background-negative: #6e0b1e;
    --color-container-background-neutral: #3c4348;
    --color-container-background-new: #6c0046;
    --color-container-background-positive: #005419;
    --color-container-background-primary: #21262a;
    --color-container-background-quaternary: #4c555b;
    --color-container-background-secondary: #2b3135;
    --color-container-background-special-use-1: #205ea3;
    --color-container-background-special-use-2: #006a56;
    --color-container-background-special-use-3: #b61a37;
    --color-container-background-special-use-4: #5d686f;
    --color-container-background-tertiary: #3c4348;
    --color-container-border-accent: #00b3c2;
    --color-container-border-attention: #a53900;
    --color-container-border-complementary: #727e85;
    --color-container-border-info: #8f4bff;
    --color-container-border-negative: #b61a37;
    --color-container-border-positive: #00892e;
    --color-container-border-primary: #3c4348;
    --color-container-border-secondary: #4c555b;
    --color-container-border-tertiary: #5d686f;
    --color-container-overlay: rgba(0, 0, 0, 0.5);
    --color-data-attention: #ff8000;
    --color-data-attention-100: #ff6a00;
    --color-data-attention-200: #ff8000;
    --color-data-attention-300: #ff9331;
    --color-data-attention-400: #ffac61;
    --color-data-category-1: #34bfff;
    --color-data-category-1-background: #385564;
    --color-data-category-2: #008481;
    --color-data-category-2-background: #2e494b;
    --color-data-category-3: #9457fa;
    --color-data-category-3-background: #4b4063;
    --color-data-category-4: #ff9331;
    --color-data-category-4-background: #614c3b;
    --color-data-category-5: #f25a52;
    --color-data-category-5-background: #5e4041;
    --color-data-category-6: #ffee00;
    --color-data-category-6-background: #615e31;
    --color-data-category-7: #e31c9e;
    --color-data-category-7-background: #5b3450;
    --color-data-category-8: #a898ff;
    --color-data-category-8-background: #4f4d64;
    --color-data-category-9: #0077c5;
    --color-data-category-9-background: #2e4658;
    --color-data-category-10: #15efe9;
    --color-data-category-10-background: #325e5f;
    --color-data-negative: #e43834;
    --color-data-negative-100: #d52b1e;
    --color-data-negative-200: #e43834;
    --color-data-negative-300: #f25a52;
    --color-data-negative-400: #ff8979;
    --color-data-neutral: #8d9096;
    --color-data-neutral-100: #8d9096;
    --color-data-neutral-200: #babec5;
    --color-data-neutral-300: #e3e5e8;
    --color-data-neutral-400: #f4f5f8;
    --color-data-positive: #53b700;
    --color-data-positive-100: #2ca01c;
    --color-data-positive-200: #53b700;
    --color-data-positive-300: #7fd000;
    --color-data-positive-400: #a9e838;
    --color-data-primary: #21abf6;
    --color-data-primary-100: #0077c5;
    --color-data-primary-200: #0097e6;
    --color-data-primary-300: #21abf6;
    --color-data-primary-400: #34bfff;
    --color-data-secondary: #00c1bf;
    --color-data-secondary-100: #008481;
    --color-data-secondary-200: #00a6a4;
    --color-data-secondary-300: #00c1bf;
    --color-data-secondary-400: #00d7d7;
    --color-data-tertiary: #9457fa;
    --color-data-tertiary-100: #6436af;
    --color-data-tertiary-200: #7a3dd8;
    --color-data-tertiary-300: #9457fa;
    --color-data-tertiary-400: #a898ff;
    --color-divider-inverse: #21262a;
    --color-divider-primary: #859299;
    --color-divider-secondary: #5d686f;
    --color-divider-tertiary: #3c4348;
    --color-focus-indicator: #00b3c2;
    --color-icon-accent: #00b3c2;
    --color-icon-complementary: #ffffff;
    --color-icon-disabled: #5d686f;
    --color-icon-inverse: #21262a;
    --color-icon-primary: #ffffff;
    --color-icon-secondary: #f0f4f6;
    --color-input-background-disabled: #21262a;
    --color-input-background-error: #4f0513;
    --color-input-background-error-focus: rgba(64, 3, 14, 0.7);
    --color-input-background-primary: #21262a;
    --color-input-background-primary-active: #21262a;
    --color-input-background-primary-focus: #21262a;
    --color-input-background-primary-hover: #2b3135;
    --color-input-background-primary-selected: #00b3c2;
    --color-input-background-readonly: #3c4348;
    --color-input-background-secondary: #727e85;
    --color-input-background-secondary-active: #9aa7b0;
    --color-input-background-secondary-focus: #859299;
    --color-input-background-secondary-hover: #859299;
    --color-input-background-tertiary: #21262a;
    --color-input-background-tertiary-active: #3c4348;
    --color-input-background-tertiary-focus: #2b3135;
    --color-input-background-tertiary-hover: #2b3135;
    --color-input-background-warning: #481200;
    --color-input-background-warning-focus: rgba(72, 18, 0, 0.7);
    --color-input-border-disabled: #3c4348;
    --color-input-border-error: #f7576c;
    --color-input-border-primary: #5d686f;
    --color-input-border-primary-active: #00b3c2;
    --color-input-border-primary-focus: #00b3c2;
    --color-input-border-primary-hover: #9aa7b0;
    --color-input-border-primary-selected: #00b3c2;
    --color-input-border-readonly: #adbac2;
    --color-input-border-secondary: #4c555b;
    --color-input-border-secondary-active: #00b3c2;
    --color-input-border-secondary-focus: #00b3c2;
    --color-input-border-secondary-hover: #859299;
    --color-input-border-tertiary: #727e85;
    --color-input-border-tertiary-active: #00b3c2;
    --color-input-border-tertiary-focus: #00b3c2;
    --color-input-border-tertiary-hover: #adbac2;
    --color-input-border-warning: #f88a42;
    --color-input-label: #c3ced5;
    --color-input-placeholder: #727e85;
    --color-input-placeholder-disabled: #3c4348;
    --color-link-background: #003c42;
    --color-link-background-visited: #003c42;
    --color-link-text: #00b3c2;
    --color-link-text-active: #38e8f6;
    --color-link-text-complementary: #00b3c2;
    --color-link-text-complementary-visited: #00b3c2;
    --color-link-text-focus: #00d0e0;
    --color-link-text-hover: #00d0e0;
    --color-link-text-visited: #00b3c2;
    --color-message-background-received: #3c4348;
    --color-message-background-sent: #00b3c2;
    --color-message-text-received: #ffffff;
    --color-message-text-sent: #21262a;
    --color-overlay: rgba(0, 0, 0, 0.5);
    --color-page-background-accent: #3c4348;
    --color-page-background-primary: #181c1f;
    --color-page-background-secondary: #21262a;
    --color-page-background-tertiary: #2b3135;
    --color-progress-indicator: #00b3c2;
    --color-selection-indicator: #00b3c2;
    --color-shadow: rgba(0, 0, 0, 0.2);
    --color-text-accent: #00b3c2;
    --color-text-complementary: #ffffff;
    --color-text-disabled: #4c555b;
    --color-text-highlight: #17497f;
    --color-text-inverse: #21262a;
    --color-text-primary: #ffffff;
    --color-text-quaternary: #adbac2;
    --color-text-secondary: #f0f4f6;
    --color-text-tertiary: #d5dee3;
    --color-track-primary: #3c4348;
    --color-track-secondary: #5d686f;
    --color-ui-attention: #f88a42;
    --color-ui-attention-active: #ffbe6f;
    --color-ui-attention-hover: #fca354;
    --color-ui-beta: #00d0e0;
    --color-ui-completed: #00b3c2;
    --color-ui-discover: #3492ef;
    --color-ui-info: #a275ff;
    --color-ui-negative: #f7576c;
    --color-ui-negative-active: #ff9ba4;
    --color-ui-negative-hover: #ff808c;
    --color-ui-neutral: #9aa7b0;
    --color-ui-neutral-active: #c3ced5;
    --color-ui-neutral-focus: #adbac2;
    --color-ui-neutral-hover: #adbac2;
    --color-ui-neutral-selected: #c3ced5;
    --color-ui-new: #f54bac;
    --color-ui-positive: #00a63b;
    --color-ui-primary: #00b3c2;
    --color-ui-primary-active: #38e8f6;
    --color-ui-primary-focus: #00d0e0;
    --color-ui-primary-hover: #00d0e0;
    --color-ui-secondary: #f0f4f6;
    --color-ui-secondary-active: #d5dee3;
    --color-ui-secondary-focus: #e2e9ed;
    --color-ui-secondary-hover: #e2e9ed;
    --color-ui-tertiary: #3c4348;
    --color-ui-tertiary-active: #5d686f;
    --color-ui-tertiary-focus: #4c555b;
    --color-ui-tertiary-hover: #4c555b;
}
