.container {
    max-width: var(--readable-max-width);
}

.altStyleContainer {
    border: 1px solid var(--border-light-color);
    padding: var(--spacing-6) var(--spacing-6);
}

.altText {
    /*  height: 110px; */
}

.file {
    border: 1px solid var(--border-light-color);
    background-color: var(--bg-lvl-1-color);
    padding: var(--spacing-3) var(--spacing-6);
    max-width: var(--readable-max-width);
}

.nameText {
    overflow-wrap: anywhere;
}

.removeButton {
    min-width: var(--spacing-7);
}

.thumbnail {
    max-width: 100px;
}
