/* File allow-listed for globally scoped CSS. Reach out to #fep-team with questions */
/* stylelint-disable css-modules/no-global-scoped-selector */

:root {
    /*  Variants */
    --hero-bg--default: var(--bg-lvl-1-color);
    --hero-bg--info: rgba(197, 225, 255, 0.2); /*  Blueberry-20 at 20% */
    --hero-bg--new: rgba(240, 233, 255, 0.2); /*  Eggplant-10 at 20% */
    --hero-bg--success: rgba(216, 255, 219, 0.2); /*  Kiwi-10 at 20% */
    --hero-bg--warning: rgba(255, 234, 199, 0.2); /*  Persimmon-10 at 20% */
    --hero-bg--error: rgba(255, 212, 216, 0.2); /* Watermelon-20 at 20% */
    --hero-bg--base: var(--bg-lvl-2-color);
}

.root {
    padding: var(--spacing-10) 0;
}

.withSecondary {
    padding: var(--spacing-8) 0;
}

.secondaryContent {
    order: 1;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.secondaryContent > img {
    max-width: 100%;
    max-height: 100%;
}


/*  TO-DO For Color 2.0 update: */

/*  Variant names should be decoupled from the value in order to scale. */
.coconut {
    background-color: var(--hero-bg--default);
}

.cornflower {
    background-color: var(--hero-bg--info);
}

.cucumber {
    background-color: var(--hero-bg--success);
}

.radish {
    background-color: var(--hero-bg--new);
}

.squash {
    background-color: var(--hero-bg--warning);
}

.yam {
    background-color: var(--hero-bg--error);
}

.jasmine {
    background-color: var(--bg-lvl-2-color);
}
