/* stylelint-disable css-modules/no-global-scoped-selector */

/*********************************************
    APOLLO THEME
    Temporary theme used for GenAI implementation in the app. This is an adaptation of the Apollo Component Library from Intuit.
    Theming is done in lieu of building new components. Once GenAI integration is complete, reskin can be removed.

    Apollo Library - Figma: https://www.figma.com/file/WHtRa6TlW5hkcG41ZCXDi8/Apollo-Library-(Beta)
*********************************************/

:global(.theme-apollo) {
    /* Apollo Semantic tokens */
    --apollo-ui-primary: rgba(35, 108, 255, 1);
    --apollo-ui-primary-hover: #245dcc;
    --apollo-bg-primary: var(--brand-coconut);
    --apollo-bg-primary-hover: rgba(0, 119, 197, 0.1);
    --apollo-generated-bg-color: rgba(35, 108, 255, 0.1);
    --apollo-generated-border: 1px solid var(--apollo-ui-primary);
    --apollo-chat-border-radius: 4px;
    --apollo-card-border-radius: 12px;

    /* Mailchimp Semantic tokens */
    --interactive-color: var(--apollo-ui-primary);
    --interactive-hover-color: var(--apollo-ui-primary-hover);

    --border-color: var(--apollo-ui-primary);
    --border-interactive-color: var(--apollo-ui-primary-hover);
    --intuit-assist-secondary-color: #38e8f6;
    --intuit-assist-tertiary-color: #5dabff;

    --bg-lvl-1-color: var(--apollo-generated-bg-color);
    --bg-interactive-color: var(--apollo-bg-primary-hover);

    /* Intuit Assist Badge Component
    * Semantic tokens built on top of Badge. Use Default variant. */
    --badge-bg--default: var(--apollo-bg-primary);
    --badge-border: 1px solid var(--apollo-ui-primary);
    --badge-font-weight: var(--font-weight-bold);
    --badge-text-transform: uppercase;

    /* Intuit Assist Loading Component */
    --indicator-sizing-small: 0;
    --indicator-sizing-large: 0;

    /* Intuit Assist Button Component */
    --button-secondary-border-width: 2px;
    --button-secondary-text-color: var(--text-primary-color);
    --button-secondary-text-color-hover: var(--text-primary-color);
}
