.root {
    color: var(--text-headline-primary-color);
    letter-spacing: var(--letter-spacing-heading);
}

/* Display styles using Means */
.display-1 {
    font: var(--font-display-1);
}

.display-2 {
    font: var(--font-display-2);
}

.display-3 {
    font: var(--font-display-3);
}

.display-4 {
    font: var(--font-display-4);
}

/* Heading styles using Graphik */
.heading-1 {
    font: var(--font-heading-1);
    font-weight: var(--font-heading-weight);
}

.heading-2 {
    font: var(--font-heading-2);
    font-weight: var(--font-heading-weight);
}

.heading-3 {
    font: var(--font-heading-3);
    font-weight: var(--font-heading-weight);
}

.heading-4 {
    font: var(--font-heading-4);
    font-weight: var(--font-heading-weight);
}

.heading-5 {
    font: var(--font-heading-5);
    font-weight: var(--font-heading-weight);
}

.heading-6 {
    font: var(--font-heading-6);
    font-weight: var(--font-heading-weight);
}

.heading-display {
    letter-spacing: var(--letter-spacing-display);
}

.text-wrap {
    text-wrap: wrap;
}
.text-nowrap {
    text-wrap: nowrap;
}
.text-balance {
    text-wrap: balance;
}
.text-pretty {
    text-wrap: pretty;
}

/*  [EEE3-2693]: Fix for crashing email checklist. Wrap with <span>s to get around a Google Translate issue. */
.temporarySpan {
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    white-space: inherit;
}
