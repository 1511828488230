.root {
    display: flex;
    gap: var(--spacing-2);
    flex-wrap: nowrap;
    align-items: center;
    font-size: var(--font-size-component-small);
    line-height: var(--line-height-component);
}

.icon svg {
    color: var(--color-text-inverse);
    vertical-align: middle;
}

.default circle { fill: var(--color-ui-neutral); }
.success circle { fill: var(--color-ui-positive); }
.warning circle { fill: var(--color-ui-attention); }
.error path { fill: var(--color-ui-negative); }
.info circle { fill: var(--color-ui-info); }
