/* stylelint-disable css-modules/no-global-scoped-selector */
:root {
    --box-shadow-offset: calc(var(--base-unit) * -1);
    --feedback-block-border-left: 0;
    --feedback-block-border-radius: 0;

    /* Background Colors */
    --feedback-block-bg-color: var(--color-page-background-secondary);
    --feedback-block-error-bg-color: var(--color-page-background-secondary);
    --feedback-block-warning-bg-color: var(--color-page-background-secondary);
    --feedback-block-success-bg-color: var(--color-page-background-secondary);
    --feedback-block-info-bg-color: var(--color-page-background-secondary);

    /* Border Colors */
    --feedback-block-border-color: var(--border-light-color);
    --feedback-block-error-border-color: var(--feedback-block-border-color);
    --feedback-block-warning-border-color: var(--feedback-block-border-color);
    --feedback-block-success-border-color: var(--feedback-block-border-color);
    --feedback-block-info-border-color: var(--feedback-block-border-color);
}

/*  General Styles */
.root {
    background-color: var(--feedback-block-bg-color);
    padding: var(--spacing-5) var(--spacing-4);
    box-shadow: var(--box-shadow-offset) 0 0 0 var(--box-shadow-color);
    border: 1px solid var(--feedback-block-border-color);
    border-left:
        var(--feedback-block-border-left) solid
        var(--feedback-block-border-color);
    border-radius: var(--feedback-block-border-radius);

    /*  Variable updates for variants */
    &.default {
        --box-shadow-color: var(--text-primary-color);
    }
    &.error {
        --box-shadow-color: var(--border-error-color);
        --feedback-block-border-color: var(--feedback-block-error-border-color);
        background-color: var(--feedback-block-error-bg-color);
    }
    &.warning {
        --box-shadow-color: var(--border-warning-color);
        --feedback-block-border-color: var(--feedback-block-warning-border-color);
        background-color: var(--feedback-block-warning-bg-color);
    }
    &.success {
        --box-shadow-color: var(--border-success-color);
        --feedback-block-border-color: var(--feedback-block-success-border-color);
        background-color: var(--feedback-block-success-bg-color);
    }
    &.info {
        --box-shadow-color: var(--border-info-color);
        --feedback-block-border-color: var(--feedback-block-info-border-color);
        background-color: var(--feedback-block-info-bg-color);
    }
}

/*  Icon */
.icon svg {
    fill: var(--box-shadow-color);
    vertical-align: middle;
}

/*  Feedback Content */
.content {
    width: 100%;
}

.copy {
    flex-basis: 0;
    flex-grow: 2;

    /*  Take text font-weight instead of bold */
    a {
        font-weight: inherit;
    }
}

:global(.mcds-redesign-molecules) {
    /*  General Styles */
    .root {
        align-items: center;
        padding: var(--space-component-inline-padding-xx-large);
        background-color: var(--color-container-background-secondary);
        box-shadow: none;
        border: 1px solid var(--color-container-border-secondary);
        border-radius: var(--radius-small);

        /*  Variable updates for variants */
        &.error {
            background-color: var(--color-container-background-secondary);
            .icon {
                path {
                    fill: var(--color-ui-negative);
                }
            }
        }
        &.warning {
            background-color: var(--color-container-background-secondary);
        }
        &.success {
            background-color: var(--color-container-background-secondary);
        }
    }

    .root > div {
        /*  This is a workaround for the issue with useWinkBreakpoints */
        @media screen and (max-width: 640px) {
            align-items: baseline;
        }
    }

    /*  Icon */
    .icon svg {
        path {
            fill: var(--color-container-background-secondary);
        }
        vertical-align: middle;
    }

    /*  Feedback Content */
    .content {
        width: 100%;
        align-content: center;
        gap: var(--space-component-gap-medium);
    }

    .copy {
        flex-basis: 0;
        flex-grow: 2;
        line-height: var(--line-height-component);
        align-items: center;
    }

    .default circle {
        fill: var(--color-ui-neutral);
    }
    .success circle {
        fill: var(--color-ui-positive);
    }
    .warning circle {
        fill: var(--color-ui-attention);
    }
    .error circle {
        fill: var(--color-container-background-secondary);
    }
}
