.root {
    height: 100%;
    display: flex;
    flex-direction: column;

    > [data-full-height-item] {
        flex-grow: 1;

        /*  flex-basis 0 makes this component work properly */

        /*  in Safari. Not too sure why. /shrug */
        flex-basis: 0;
    }
}


/*  Non-centered layouts, by default, apply full height to the last child */

/*  unless a child has `[data-full-height-item]`. */
.root:not(.center) {
    > :last-child {
        flex-grow: 1;

        /*  flex-basis 0 makes this component work properly */

        /*  in Safari. Not too sure why. /shrug */
        flex-basis: 0;
    }


    /*  If we specify a different child to have `[data-full-height-item]`, we */

    /*  should cancel out the last child's flex-grow. */
    > [data-full-height-item] ~ :last-child {
        flex-grow: 0;
    }
}


/*  Center children horizontally and vertically */
.center {
    justify-content: center;
    align-items: center;
}
