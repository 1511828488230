@import (reference) '@mc/wink/styles/mixins.less';

.root {
    display: grid;
}

.layout-spacing({
    .gap@{size} {
        gap: @value;
    }
});
