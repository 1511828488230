.root {
    max-width: var(--readable-max-width);

    fieldset > & {
        font: var(--font-text-small);
    }

    input {
        /* Input styles are visually implemented on the inputWrapper */
        border: none;
        flex: 1;
        width: 100%;
        outline: none;
        padding:
            var(--input-padding-top, 10px) 0 var(--input-padding-bottom, 10px)
            0;
        background-color: transparent;

        &::placeholder {
            color: var(--text-secondary-color);
        }

        &::-ms-clear {
            display: none;
        }
    }
}

.inputWrapper {
    font: inherit;
    font-size: var(--input-font-size, var(--font-size-medium));
    line-height: var(--line-height-medium);
    width: 100%;
    padding-top: var(--input-padding-top, 10px);
    padding-bottom: var(--input-padding-bottom, 10px);
    padding-left: var(--input-padding-left, var(--spacing-4));
    padding-right: var(--input-padding-right, var(--spacing-4));
    border: 1px solid var(--border-input-color);
    color: var(--text-primary-color);
    background-color: var(--bg-lvl-1-color);
    border-radius: var(--border-input-radius);
    box-sizing: border-box;
    transition:
        box-shadow 200ms ease-in-out,
        border-color 200ms ease-in-out,
        background-color 150ms var(--animation-curve);

    /*  Mobile Safari workaround */
    background-clip: padding-box;

    &:not([disabled]):focus {
        outline: 2px solid var(--border-interactive-color);
        outline-offset: 1px;
        border: 1px solid var(--border-input-color-hover);

        /* Mobile Safari workaround */
        box-shadow: inset 0 -2px 0 transparent;
    }

    &[disabled] {
        cursor: not-allowed;
        background-color: transparent;
        color: var(--disabled-color);
        border-color: var(--disabled-border-color);
    }

    &[readonly] {
        background-color: transparent;
    }

    &::placeholder {
        color: var(--text-secondary-color);
    }

    &::-ms-clear {
        display: none;
    }
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
    padding: 0;

    &:focus-within {
        outline: 2px solid var(--border-interactive-color);
        outline-offset: 1px;
        border: 1px solid var(--border-input-color-hover);

        /* Mobile Safari workaround */
        box-shadow: inset 0 -2px 0 transparent;
    }

    &:not(.disabledInput):not(.readOnlyInput):hover {
        border-color: var(--border-dark-color);
    }
}

.inputWrapper > :first-child {
    padding-left: var(--input-padding-left, var(--spacing-4));
}

.inputWrapper > :last-child {
    padding-right: var(--input-padding-right, var(--spacing-4));
}

.inputPrefix {
    padding-right: var(--spacing-1);
}
.inputSuffix {
    padding-left: var(--spacing-1);
}

.disabledInput,
.disabledInput input {
    cursor: not-allowed;
    background-color: transparent;
    color: var(--disabled-color);
    border-color: var(--disabled-border-color);
}

.readOnlyInput {
    background-color: transparent;
}

.before {
    display: flex;
    justify-content: space-between;

    /*  Add bottom margin to `.before`'s direct children. If elements before the */

    /*  input are visually empty, no margin should display before the input. */
    > * {
        margin-bottom: var(--spacing-2);
    }
}

/*  Add top margin to elements after the input. If no elements exist after the */

/*  input, no margin should display after the input. */
.after {
    margin-top: var(--spacing-2);
}

.secondary {
    color: var(--text-secondary-color);

    &.nonText {
        line-height: 0px;
    }
}

.error .inputWrapper {
    border-color: var(--border-error-color);

    &:focus-within {
        outline-color: var(--border-error-color);
    }
}

.errorMessage {
    color: var(--text-error-color);

    a {
        color: inherit;
    }
}

:global(.mcds-redesign-molecules) {
    .root {
        /* variables based on default size large */
        --mcds-input-font-size: var(--font-size-component-small);
        --mcds-input-line-height: calc(var(--base-unit) * 5);
        --mcds-input-wrapper-padding:
            var(--space-component-stack-padding-large)
            var(--space-component-inline-padding-large);
        --mcds-input-padding: var(--space-component-stack-padding-x-small) 0;
        --mcds-input-height: calc(var(--base-unit) * 10);
        --mcds-secondary-font-size: var(--font-size-component-small);
    }

    /* variables based on size medium */
    .medium {
        --mcds-input-font-size: var(--font-size-component-x-small);
        --mcds-input-line-height: calc(var(--base-unit) * 4);
        --mcds-input-wrapper-padding:
            var(--space-component-stack-padding-small)
            var(--space-component-inline-padding-medium);
        --mcds-input-padding: var(--space-component-stack-padding-small) 0;
        --mcds-input-height: calc(var(--base-unit) * 8);
        --mcds-secondary-font-size: var(--font-size-component-x-small);
    }

    .root {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: var(--font-family-component);
        font-size: var(--mcds-input-font-size);
        gap: var(--space-component-gap-small);
        max-width: 100%;
    }

    .before {
        display: flex;
        align-items: center;
        gap: var(--space-component-gap-x-small);
        align-self: stretch;

        > * {
            margin-bottom: 0;
        }

        .requiredMarker {
            color: var(--color-ui-negative);
            font-style: normal;
            font-weight: 500;
            letter-spacing: -0.12px;
            line-height: var(--mcds-input-line-height);
        }

        label {
            flex: 1 0 0;
            color: var(--color-text-secondary);

            /* Component/Small Size=large*/
            font-style: normal;
            font-weight: var(--font-weight-input-label);
            line-height: var(--mcds-input-line-height);
        }
    }

    .secondary,
    .after {
        color: var(--color-text-tertiary);
        font-family: var(--font-family-component);
        font-style: normal;
        font-weight: var(--font-weight-component);
        line-height: calc(var(--base-unit) * 4);
    }

    .secondary {
        font-size: var(--mcds-secondary-font-size);
    }

    .after {
        font-size: var(--font-size-component-x-small);

        & > * {
            font-size: var(--font-size-component-x-small);
        }
    }


    .secondary {
        /* overwrite Plain Button component styles here once it ready */
        &.nonText {
            line-height: 0px;
        }
    }

    .inputWrapper > :first-child {
        padding-left: 0;
    }

    .inputWrapper > :last-child {
        padding-right: 0;
    }

    .inputWrapper {
        display: flex;
        gap: var(--space-component-gap-small);
        border-radius: var(--radius-small);
        padding: var(--mcds-input-wrapper-padding);
        outline-offset: -1px;
        outline: 1px solid var(--color-input-border-primary);
        background: var(--color-input-background-primary);
        color: var(--color-text-primary);
        font-size: var(--mcds-input-font-size);
        border: none;
        height: var(--mcds-input-height);

        &.disabledInput {
            outline: 1px solid var(--color-input-border-disabled);
            background: var(--color-input-background-disabled);
            pointer-events: none;
            cursor: not-allowed;
        }

        &.readOnlyInput {
            outline: 1px solid var(--color-input-border-readonly);
            background: var(--color-input-background-readonly);
            pointer-events: none;
            cursor: not-allowed;
        }

        &.readOnlyInput:focus-within {
            outline: none;
            outline-offset: 0;
            box-shadow: none;
        }

        &:not(.disabledInput):not(.readOnlyInput):hover {
            outline: 1px solid var(--color-input-border-primary-hover);
            background: var(--color-input-background-primary-hover);
        }

        &:not(.disabledInput):not(.readOnlyInput):focus-within {
            background: var(--color-input-background-primary-focus);
            border: none;
            box-shadow: none;
            outline-offset: -2px;
            outline: 2px solid var(--color-input-border-primary-focus);
        }

        input {
            padding: var(--mcds-input-padding);
            flex: 1 0 0;
            line-height: var(--mcds-input-line-height);

            &::placeholder {
                color: var(--color-text-quaternary);
            }

            &[readonly] {
                color: var(--color-text-primary);
            }

            &[readonly]:focus {
                outline: none;
                outline-offset: 0;
                box-shadow: none;
            }
        }
    }

    .inputPrefix,
    .inputSuffix {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: var(--mcds-input-font-size);
        color: var(--color-text-secondary);
        line-height: var(--mcds-input-line-height);

        svg {
            width: calc(var(--base-unit) * 4);
            height: calc(var(--base-unit) * 4);
        }

        button {
            padding: var(--space-component-stack-padding-small) var(--space-component-inline-padding-x-small);
        }
    }

    .after {
        margin-top: 0;
        color: var(--color-text-secondary);
    }

    /* ERROR INPUT */
    .error .inputWrapper {
        color: var(--color-text-primary);
        outline-offset: -1px;
        outline: 1px solid var(--color-input-border-error);
        background: var(--color-input-background-error);

        &:not(.disabledInput):not(.readOnlyInput):hover {
            outline: 1px solid var(--color-input-border-error);
            background: var(--color-input-background-error-focus);
        }

        &:not(.disabledInput):not(.readOnlyInput):focus-within {
            background: var(--color-input-background-error-focus);
            border: none;
            box-shadow: none;
            outline-offset: -2px;
            outline: 2px solid var(--color-input-border-error);
        }
    }

    /* WARNING INPUT */
    .warning .inputWrapper {
        color: var(--color-text-primary);
        outline-offset: -1px;
        outline: 1px solid var(--color-input-border-warning);
        background: var(--color-input-background-warning);

        &:not(.disabledInput):not(.readOnlyInput):hover {
            outline: 1px solid var(--color-input-border-warning);
            background: var(--color-input-background-warning-focus);
        }

        &:not(.disabledInput):not(.readOnlyInput):focus-within {
            background: var(--color-input-background-warning-focus);
            color: var(--color-text-primary);
            box-shadow: none;
            outline-offset: -2px;
            outline: 2px solid var(--color-input-border-warning);
        }
    }

    .errorMessage,
    .warningMessage {
        /* overwrite iconbadge style - it dont have size property*/
        color: var(--color-ui-negative);
        > * {
            padding: 0;
            gap: var(--space-component-gap-small);
        }
        svg {
            width: calc(var(--base-unit) * 4);
            height: calc(var(--base-unit) * 4);
        }
    }

    .warningMessage {
        color: var(--color-text-secondary);
    }
}
