/* stylelint-disable selector-pseudo-class-no-unknown */
.root {
    display: inline-block;
    box-shadow: none;
    outline: none;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    min-width: calc(var(--base-unit) * 12);
    position: relative;
    padding: 0 var(--spacing-1);

    /*  Copy native <select> cursor. */
    cursor: default;
    vertical-align: bottom;
    line-height: var(--line-height-text);
    transition: background-color 150ms var(--animation-curve), border-radius 150ms var(--animation-curve);
    z-index: 0;

    /* Focus */
    &:focus-within {
        border-radius: var(--border-pill-radius);
        outline: 2px solid var(--border-interactive-color);
        outline-offset: 1px;

        /*  Mobile Safari workaround */
        box-shadow: inset 0 -2px 0 transparent;
    }


    /*  Remove duplicate focus style for combobox. :focus-within on .root handles */

    /*  the overall focus style. */
    [role="combobox"]:focus {
        outline: none;
    }


    /*  Expand the combobox's click area to the whole component. */
    [role="combobox"]::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    /* Hover */
    &:not([disabled]):hover {
        background-color: var(--bg-lvl-2-color);
        transition: background-color 50ms var(--animation-curve);
        border-radius: var(--border-pill-radius);
    }

    label {
        display: inline-block;
        font-weight: var(--font-weight-bold);
    }

    select {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        border: none;
        border-radius: 0;
        width: 100%;
        z-index: var(--z-base);
    }

    .selectedValue {
        display: flex;
        padding-left: var(--spacing-1);
        font-weight: var(--font-weight-bold);
        color: var(--interactive-color);
        max-width: calc(var(--readable-max-width) / 2);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
    }

    .indicator {
        margin-left: var(--spacing-1);
        line-height: 0;
    }

    .indicator svg {
        fill: var(--interactive-color);
    }

    &.disabled {
        cursor: not-allowed;

        .selectedValue {
            color: var(--disabled-color);
        }

        .indicator svg {
            fill: var(--disabled-color);
        }
    }
}

.listbox {
    display: inline-flex;
}

:global(.mcds-redesign-molecules) {

    .root {
        gap: var(--space-component-gap-x-small);
        font-family: var(--font-family-component);
        font-style: normal;
        padding: 0;
        line-height: var(--line-height-component);


        /* Focus */
        &:focus-within {
            border-radius: 0;
            outline: none;
            outline-offset: 0;

            /*  Mobile Safari workaround */
            box-shadow: none;

            .selectedValue {
                color: var(--color-action-standard-focus);
                border-radius: var(--radius-small);
                outline: 2px solid var(--color-ui-primary);
                background: var(--color-action-standard-subtle-focus);
                box-shadow: none;
                outline-offset: 1px;
            }

            .indicator svg {
                fill: var(--color-action-standard-focus);
            }
        }

        select:open + .selectedValue,
        &:focus-within select:open + .selectedValue {
            color: var(--color-action-standard-active);
            border-radius: var(--radius-action);
            background: var(--color-action-standard-subtle-active);
            outline: none;

            .indicator svg {
                fill: var(--color-action-standard-active);
            }
        }

        /* Hover */
        &:not([disabled]):hover {
            background-color: transparent;
            transition: none;
            border-radius: 0;

            .selectedValue {
                color: var(--color-action-standard-hover);
                border-radius: var(--radius-action);
                background: var(--color-action-standard-subtle-hover);
            }

            .indicator svg {
                fill: var(--color-action-standard-hover);
            }
        }

        label {
            display: flex;
            align-items: center;
            gap: var(--space-component-gap-x-small);
            align-self: stretch;
            color: var(--color-text-secondary);
            font-weight: var(--font-weight-component);
        }

        .selectedValue {
            padding: var(--space-component-inline-padding-xx-small) var(--space-component-inline-padding-xx-small) var(--space-component-inline-padding-xx-small) var(--space-component-stack-padding-small);
            justify-content: center;
            align-items: center;
            gap: var(--space-component-gap-x-small);
            font-weight: var(--font-weight-component);
            color: var(--color-action-standard);
        }

        button.selectedValue {
            padding: var(--space-component-inline-padding-xx-small) var(--space-component-inline-padding-xx-small) var(--space-component-inline-padding-xx-small) var(--space-component-stack-padding-small) !important;

            &:focus,
            &:hover {
                color: var(--color-action-standard-hover);
                outline: 0;
                box-shadow: none;

                .indicator svg {
                    fill: var(--color-action-standard-hover);
                }
            }

            &:focus-visible {
                outline-offset: 1px;
                color: var(--color-action-standard-focus);
                border-radius: var(--radius-small);
                outline: 2px solid var(--color-ui-primary);
                background: var(--color-action-standard-subtle-focus);
                box-shadow: none;
            }

            &:active {
                color: var(--color-action-standard-active);
                border-radius: var(--radius-action);
                background: var(--color-action-standard-subtle-active);

                .indicator svg {
                    fill: var(--color-action-standard-active);
                }
            }
        }

        .indicator {
            margin-left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .indicator svg {
            fill: var(--color-action-standard);
        }

        &.disabled {
            opacity: var(--opacity-disabled);
            pointer-events: none;
        }

    }

    .medium {
        font-size: var(--font-size-component-small);
        height: calc(var(--base-unit) * 6);
    }

    .large {
        font-size: var(--font-size-component-medium);
        height: calc(var(--base-unit) * 7);

    }

    .required {
        color: var(--color-ui-negative);
        font-family: var(--font-family-component);
        font-size: var(--font-size-component-small);
        font-style: normal;
        font-weight: var(--font-weight-body-bold);
        line-height: calc(var(--base-unit) * 5);
        letter-spacing: 0;
    }

}
