.root {
    background-color: var(--bg-lvl-1-color);
    max-width: 312px;
    font: var(--font-text-xsmall);
}

/*  Inputs */
.inputGroup {
    display: flex;

    --input-font-size: var(--font-size-small);
    --input-padding-left: var(--spacing-2);
    --input-padding-right: var(--spacing-2);
    --input-padding-top: var(--spacing-1);
    --input-padding-bottom: var(--spacing-1);

    /*  TODO: Don't override */
    --border-input-color: var(--border-color);

    > * + * {
        margin-left: var(--spacing-2);
    }
}

.inputHex {
    width: var(--spacing-10);

    input {
        text-transform: uppercase;
    }
}

.inputAlpha {
    width: var(--spacing-9);
}

.colorPreview {
    width: var(--swatch-size);
    height: var(--swatch-size);
    display: inline-block;
    border: 1px solid var(--border-light-color);
}


/*  Rgb Input */
.rgbContainer {
    display: grid;
    grid-template-columns: repeat(3, 45px);
    gap: var(--spacing-1);
}

.colorTypeSwitcher {
    padding: var(--spacing-4) 0;
}

:global(.mcds-redesign-molecules) {
    .input,
    .inputHex {
        gap: 0;
    }
}
