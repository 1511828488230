/* stylelint-disable css-modules/no-global-scoped-selector */
:root {
    --accordion-border-radius-first: var(--border-data-radius) var(--border-data-radius) 0 0;
    --accordion-border-radius-last: 0 0 var(--border-data-radius) var(--border-data-radius);
    --accordion-border-radius-only-one: var(--border-data-radius);
    --accordion-border-top: 1px solid var(--border-color);
}

.root {
    /*  Rounded Accordion Group for border, hover and focus states */
    .listItem:first-of-type,
    .listItem:first-of-type .item,
    .listItem:first-of-type .heading:hover {
        border-radius: var(--accordion-border-radius-first);
    }
    .listItem:last-of-type,
    .listItem:last-of-type .item {
        border-radius: var(--accordion-border-radius-last);
    }
    .listItem:only-of-type,
    .listItem:only-of-type .item {
        border-radius: var(--accordion-border-radius-only-one);
    }


    /*  Apply borders on the list item instead of .item so that outlines on .item */

    /*  are applied evenly, overlapping this border. */
    li {
        border: 1px solid var(--border-color);
        border-top: var(--accordion-border-top);
    }
    li + li {
        border-top: none;
    }
}

.item:focus-within {
    outline: 2px solid var(--border-interactive-color);

    /*  TODO: Test in Windows High Contrast Mode */
}

.heading {
    font-weight: var(--font-weight-bold);
    display: flex;
    align-items: center;
    padding: var(--spacing-6);
    width: 100%;
    transition: background-color var(--animation-speed-fast) var(--animation-curve);

    svg {
        /*  The SVG uses a different fill than the current color */
        fill: var(--interactive-color);
        margin-right: var(--spacing-6);
        transition: transform var(--duration-base) var(--animation-curve);
    }

    &[aria-expanded="true"] svg {
        transform: rotate(-180deg);
    }

    &:hover {
        cursor: pointer;
        background-color: var(--color-container-background-tertiary);
    }

    &:focus {
        /*  Handled by .item's focus-within */
        outline: none;
    }
}

.body {
    padding-top: var(--spacing-3);
    padding-right: var(--spacing-6);
    padding-bottom: var(--spacing-6);
    padding-left: calc(var(--spacing-6) * 3);
}
