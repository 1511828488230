.root {
    color: var(--text-secondary-color);
    font: var(--font-text-medium);
    font-weight: var(--font-weight-bold);
    white-space: nowrap;
    padding: var(--spacing-3) var(--spacing-2);

    &[aria-selected='true'] {
        color: var(--interactive-color);
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        color: var(--interactive-hover-color);
        cursor: pointer;
    }

    /*  Remove right-side spacing on last tab */
    &:last-of-type {
        margin-right: 0;
    }
}

.large {
    margin-right: calc(var(--base-unit) * 10);
}

.small {
    margin-right: var(--spacing-7);
    font: var(--font-text-small);
    font-weight: var(--font-weight-bold);
}

.focus-outline {
    &:focus {
        outline: 2px solid var(--border-interactive-color);
        outline-offset: calc(-1 * var(--base-unit) * 1.5);
    }
}
